import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import OTPInput from 'react-otp-input';

import { Box, Button, Typography, CircularProgress } from '@mui/material';
import { createTicket, requestOtp } from '../../services';

const OtpStyles = {
  textAlign: 'center',
  margin: '10px 17px',
  height: '60px',
  width: '60px',
  border: '1px solid lightgrey',
  borderRadius: '10px',
};

export default function Form({ buttonStyle, details, setOtpStatus }) {
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [otpError, setOtpError] = useState(null);
  const [counter, setCounter] = useState(59);

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [counter]);

  const handleOTP = async () => {
    setLoading(true);
    const reqData = {
      ...details,
      otp,
    };

    const response = await createTicket(reqData);
    setLoading(false);
    if (response.status === 200) {
      setOtpStatus('verified');
    } else if (response.status > 200 && response.status < 500) {
      setOtpError(response?.data?.error);
    } else {
      setOtpError('Something went wrong');
    }
  };

  const handleResendOtp = async () => {
    setLoading(true);
    const response = await requestOtp(details.phone);
    setLoading(false);
    if (response.status === 200) {
      setCounter(59);
    } else if (response.status > 200 && response.status < 500) {
      if (response.status === 404) {
        navigate('/customer-services');
      } else {
        setOtpError(response?.data?.error);
      }
    } else {
      setOtpError('Something went wrong');
    }
  };

  return (
    <div>
      <Typography my={2} variant="h6" fontWeight={600} textAlign={'center'}>
        OTP Verification
      </Typography>
      <Typography my={2} variant="body2" fontWeight={400} textAlign={'center'}>
        OTP has been sent to {details?.phone}
      </Typography>
      <Box className="otp-fields-container" my={4}>
        <OTPInput
          value={otp}
          shouldAutoFocus={true}
          inputType="number"
          placeholder="-"
          inputStyle={OtpStyles}
          focusStyle={{ outline: 'none', border: '1px solid red' }}
          onChange={setOtp}
          numInputs={4}
          renderInput={(props) => <input {...props} />}
        />
      </Box>
      <Box mb={3} textAlign={'center'}>
        {counter > 0 ? (
          <Typography variant="body2" color={'rgba(32, 32, 32, 0.40)'}>
            Resend Otp in <span style={{ color: '#006' }}> {counter}</span> sec
          </Typography>
        ) : (
          <Typography
            variant="body2"
            fontWeight={500}
            color={'#006'}
            style={{ cursor: 'pointer' }}
            onClick={handleResendOtp}
          >
            Resend OTP
          </Typography>
        )}
      </Box>
      <Box mb={2}>
        <Typography color="error" variant="caption">
          {otpError}
        </Typography>
      </Box>
      <Box textAlign={'center'}>
        <Button
          fullWidth
          variant="contained"
          size="large"
          type="submit"
          sx={buttonStyle}
          onClick={handleOTP}
          disabled={otp.length !== 4}
          endIcon={loading ? <CircularProgress size="20px" color="inherit" /> : ''}
        >
          Verify
        </Button>
      </Box>
    </div>
  );
}
