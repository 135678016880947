import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import CrossTick from '../../../assets/images/insuranceJourneyImages/cross-tick.png';

const UnCoverageExplaination = () => {
  const { productDetailsInfo } = useSelector((state) => state.InsuranceReducer);

  const { data } = productDetailsInfo.whats_not_covered;
  return (
    <Box className="uncoverage-details uncoverage-accordian-details" mt={1}>
      {data?.key_points.map((el) => (
        <Box className="uncoverage-points" key={el + 1}>
          <img src={CrossTick} alt="cross-tick" />
          <Typography>{el}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default UnCoverageExplaination;
