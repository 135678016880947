import React, { useState, memo } from 'react';
import { Box, Skeleton, createTheme, useMediaQuery } from '@mui/material';
import CliHeaders from './cliHeaders';
import CreditLimitImage from '../../../assets/images/cliImages/credit-limit.png';
import CreditLimitMobileImage from '../../../assets/images/cliImages/credit-limit-mobile.png';
import TypographyComp from './typographyComp';
import ProgressStatus from './progressStatus';

const CreditLimitImageSection = () => {
  const theme = createTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const [loaded, setLoaded] = useState(false);
  const handleImageLoad = () => setLoaded(true);
  return (
    <>
      {isLargeScreen && <CliHeaders />}
      {!isLargeScreen && (
        <>
          <TypographyComp fontWeight={700} content="Verify Phone" variant="h6" my={2} />
          <Box mb={4}>
            <ProgressStatus />
          </Box>
        </>
      )}
      {!loaded && <Skeleton variant="wave" width="100%" height="500px" />}
      <img
        src={isLargeScreen ? CreditLimitImage : CreditLimitMobileImage}
        width="100%"
        onLoad={handleImageLoad}
        style={{ display: loaded ? 'block' : 'none' }}
        alt="credit-limit-image"
      />
    </>
  );
};

export default memo(CreditLimitImageSection);
