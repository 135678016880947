import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';

const CoverageTypo = () => {
  const { productDetailsInfo } = useSelector((state) => state.InsuranceReducer);

  const { metadata } = productDetailsInfo.whats_covered;
  return (
    <Typography variant="body2" fontWeight={600}>
      {metadata?.title}
    </Typography>
  );
};

export default CoverageTypo;
