import {
  GET_COMMONDETAIL,
  GET_COMMONDETAIL_SUCCESS,
  GET_COMMONDETAIL_ERROR,
  POST_COMMONDETAIL,
  POST_COMMONDETAIL_SUCCESS,
  POST_COMMONDETAIL_ERROR,
} from '../../../utils/constant';

const initialState = {
  message: [],
  commonDetailsData: [],
  loading: false,
  loadingSucess: false,
  errorMessage: '',
  lists: [],
  postCommonDetailData: [],
  postSuccess: '',
  postErrorMessage: '',
};

const commonDetailsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_COMMONDETAIL:
      return {
        ...state,
        commonDetailsData: action.payload,
        statusCode: '',
        loading: true,
      };

    case GET_COMMONDETAIL_SUCCESS:
      return {
        ...state,
        lists: action.payload,
        errorMessage: '',
        loadingSucess: false,
      };

    case GET_COMMONDETAIL_ERROR:
      return { ...state, errorMessage: action.payload, loading: false };

    case POST_COMMONDETAIL:
      return { ...state, postCommonDetailData: action.payload, loading: true };

    case POST_COMMONDETAIL_SUCCESS:
      return {
        ...state,
        postSuccess: action.payload,
        postErrorMessage: '',
        loading: false,
      };

    case POST_COMMONDETAIL_ERROR: {
      const postError = action.payload;
      const postErrorMessage = {
        status: postError.status,
        message: postError.data.errors,
      };
      return { ...state, postErrorMessage, loading: false };
    }

    default:
      return { ...state };
  }
};

export default commonDetailsReducer;
