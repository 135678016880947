import React from 'react';

import TestimonialPic from '../../assets/images/testimonial-photo.png';

export default function Testimonials() {
  return (
    <>
      <div className="TestimonialBox" data-aos="fade-up" data-aos-duration="800">
        <div className="container">
          <div className="testiMonials">
            <div className="row align-items-end">
              <div className="col-md-4" data-aos="zoom-in" data-aos-delay="500">
                <img src={TestimonialPic} alt="Stashfin Customer - Tarun Aggarwal - Media Expert" />
                <p className="author mobileAuthor">
                  Tarun Agarwal<span>Media Expert</span>
                </p>
              </div>
              <div className="col-md-8 text-left" data-aos="fade-left" data-aos-delay="500">
                <p>"Thank You Stashfin for providing me with a credit line which helped in my adverse situation. "</p>
                <p className="author">
                  Tarun Agarwal<span>Media Expert</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
