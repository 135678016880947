import React from 'react';
import { createTheme, useMediaQuery } from '@mui/material';
import FeedbackModal from './feedbackModal';
import FeedbackDrawer from './feedbackDrawer';

const Feedback = () => {
  const theme = createTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  if (isLargeScreen) return <FeedbackModal />;
  if (!isLargeScreen) return <FeedbackDrawer />;
};

export default Feedback;
