import React, { useEffect, useState, useContext, createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getFeedbackDetails } from '../Redux/actions';

const UseContextWrapper = createContext();

export function useElements() {
  return useContext(UseContextWrapper);
}

const InsuranceContextWrapper = ({ children }) => {
  const [isBackButtonClicked, setBackbuttonPress] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!isBackButtonClicked) {
      dispatch(getFeedbackDetails(navigate));
    }
  };
  useEffect(() => {
    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', onBackButtonEvent);

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  return <UseContextWrapper.Provider value={{ setBackbuttonPress }}>{children}</UseContextWrapper.Provider>;
};

export default InsuranceContextWrapper;
