import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Carousal.scss';

const Carousal = ({ bannerData }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    customPaging: () => <div style={{ width: 30, height: 10, background: '#ddd' }} />,
  };

  return (
    <Slider {...settings}>
      {bannerData?.map((item, index) => (
        <div key={index} height="7.5rem" width="6.25rem">
          <img src={item.image_url} alt={item.name} width="100%" />
        </div>
      ))}
    </Slider>
  );
};

export default Carousal;
