import React, { useState } from 'react';
import { InputFields } from '../inputFields';
import { dateFormatter } from '../utils';

const InsuranceDateField = ({ errors, field, register, handleDobData, handleUserUpdate }) => {
  const [inputValue, setInputValue] = useState(field?.initial_value);
  return (
    <InputFields
      label={field?.label}
      type="date"
      autoComplete="off"
      value={dateFormatter(inputValue)}
      {...register(field?.key, {
        required: { value: true, message: `${field?.label} is required` },
        onChange: (e) => {
          const formattedDate = e.target.value.split('-').reverse().join('-');
          setInputValue(formattedDate);
          handleDobData({ [`${field?.key}`]: formattedDate });
          if (field?.additional_info?.fetch_premium) {
            return handleUserUpdate({ [field?.key]: formattedDate });
          }
        },
      })}
      error={!!errors?.[`${field?.key}`]}
      helperText={errors?.[`${field?.key}`]?.message}
      InputLabelProps={{
        shrink: 'true',
      }}
      disabled={!field?.editable}
      InputProps={{
        inputProps: {
          min: field?.min_value?.split('-')?.reverse()?.join('-'),
          max: field?.max_value?.split('-')?.reverse()?.join('-'),
        },
      }}
    />
  );
};

export default InsuranceDateField;
