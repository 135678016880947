import { DEAL_AND_OFFER, DEAL_AND_OFFER_SUCCESS, DEAL_AND_OFFER_ERROR } from '../../../utils/constant';

const initialState = {
  message: [],
  errorMessage: [],
  dealsAndOfferData: null,
};
const dealaAndOfferReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case DEAL_AND_OFFER:
      return {
        ...state,
        dealsAndOfferData: action.payload,
        loading: true,
        errorMessage: '',
      };
    case DEAL_AND_OFFER_SUCCESS:
      return {
        ...state,
        success: {
          message: action.payload,
        },
        loading: false,
        errorMessage: '',
      };
    case DEAL_AND_OFFER_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
export default dealaAndOfferReducer;
