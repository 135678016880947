import React from 'react';
import { Box, Stack } from '@mui/material';
import TypographyComp from './typographyComp';

const CliBankDetails = () => {
  const bankDetailsData = [
    { text: 'Account No', desc: '37474674673' },
    { text: 'IFSC Code', desc: 'FHDJFHJDH' },
    { text: 'Bank Name', desc: 'ICICI bank' },
  ];
  return (
    <Box px={2} py={3} mt={2} borderRadius={3} style={{ backgroundColor: 'rgba(38, 93, 179, 0.05)' }}>
      <Stack spacing={2.5} display="flex" justifyContent="space-between" flexDirection="column">
        {bankDetailsData?.map(({ text, desc }) => (
          <Box display="flex" justifyContent="space-between" key={text}>
            <TypographyComp content={text} variant="caption" color="rgba(0, 0, 0, 0.70);" fontWeight={500} />
            <TypographyComp
              content={desc}
              variant="caption"
              textAlign="right"
              color="rgba(0, 0, 0, 0.70)"
              fontWeight={500}
            />
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default CliBankDetails;
