import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';

const LinksAndDocuments = () => {
  const { productDetailsInfo, otpBypass } = useSelector((state) => state.InsuranceReducer);
  console.log('otpBypass:', otpBypass);
  const { metadata, data } = productDetailsInfo.documents;

  return (
    <>
      <Box className="links-doc-container">
        <Typography fontWeight={600}>{metadata?.title}</Typography>
        <Box className="links-doc">
          {data?.hyper_links.map(({ title, url }) => (
            <Typography className="link" key={title}>
              <a
                href={otpBypass ? `https://drive.google.com/viewerng/viewer?embedded=true&url=${url}` : url}
                target="_self"
                onClick={() =>
                  console.log(
                    'Clicked link:',
                    otpBypass ? `https://drive.google.com/viewerng/viewer?embedded=true&url=${url}` : url,
                  )
                }
              >
                {title}
              </a>
            </Typography>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default LinksAndDocuments;
