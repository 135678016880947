import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const colors = {
  themeColor: 'rgba(38, 93, 179, 1)',
  fadeThemeColor: 'rgba(38, 93, 179, 0.7)',
  greyColor: '#696B6E',
  lightGreyColor: '#E2E2E2',
  blackColor: '#000;',
  whiteColor: '#fff',
  error: 'rgb(212,73,46)',
};

export const InputFields = styled(TextField)(() => ({
  '& input': {
    color: `${colors.blackColor}`,
    fontWeight: 500,
  },
  '& label.Mui-focused': {
    color: `${colors.themeColor}`,
    fontSize: '14px',
    fontWeight: '600',
  },
  '& label': {
    color: `${colors.greyColor}`,
    marginLeft: '8px',
    fontSize: '14px',
    fontWeight: '500',
  },
  '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: `${colors.error}`,
  },
  '& .MuiFormLabel-root.Mui-error': {
    color: `${colors.error}`,
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: `${colors.whiteColor}`,
    borderRadius: '10px',
    '&.Mui-focused fieldset': {
      borderRadius: '10px',
      border: `1px solid ${colors.themeColor}`,
      legend: {
        marginLeft: '9px',
      },
      color: `${colors.themeColor}`,
    },
    '& fieldset': {
      borderRadius: '10px',
      border: `1px solid ${colors.lightGreyColor}`,
      legend: {
        marginLeft: '9px',
      },
    },
  },
  '& .MuiFormHelperText-root': {
    marginLeft: '0px',
    marginTop: '9px',
  },
}));

export const buttonStyle = {
  borderRadius: '98px',
  padding: '11px 0px',
  backgroundColor: `${colors.themeColor}`,
  fontWeight: 600,
  textTransform: 'none',
  ':hover': { backgroundColor: 'rgba(38, 93, 179, 0.7)' },
  '&.Mui-disabled': {
    backgroundColor: 'rgba(0, 0, 0, 0.20)',
    color: `${colors.whiteColor}`,
  },
};
