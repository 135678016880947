import axios from 'axios';

import { GET_TICKETS, GET_COMMENTS, POST_COMMENT } from '../../../utils/constant';

const adminPanelApi = process.env.REACT_APP_ADMIN_PANEL_API;

const urlParams = new URLSearchParams(window.location.search);
const authToken = urlParams?.get('auth_token');
const deviceId = urlParams?.get('device_id');

const headers = {
  auth: authToken,
  device: deviceId,
};

export const getTicketsApiCall = async () => {
  const API = `${adminPanelApi}${GET_TICKETS}`;
  try {
    const response = await axios.get(API, {
      headers,
    });
    return response;
  } catch (err) {
    return err?.response?.data;
  }
};

export const getTicketComments = async ({ ticketId }) => {
  const API = `${adminPanelApi}${GET_COMMENTS}?ticket_id=${ticketId}`;
  try {
    const response = await axios.get(API, {
      headers,
    });
    return response;
  } catch (err) {
    return err?.response?.data;
  }
};

export const postTicketComment = async ({ ticketId, userComment }) => {
  const API = `${adminPanelApi}${POST_COMMENT}`;
  const body = new FormData();
  body.append('ticket_id', ticketId);
  body.append('comment', userComment);
  try {
    const response = await axios.post(API, body);
    return response;
  } catch (err) {
    return err?.response?.data;
  }
};
