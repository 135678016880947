import React, { useState } from 'react';

import { Box, createTheme, useMediaQuery, SwipeableDrawer, styled, Backdrop, Typography } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

import Form from './form';
import TransitionsModal from '../desktopTransitionModal';

const SwipeableDrawerStyled = styled(SwipeableDrawer)({
  '& .MuiDrawer-paper': {
    borderRadius: '20px 20px 0px 0px',
  },
});

export default function OTPForm({ buttonStyle, details, setOtpStatus, setShowotpForm }) {
  const [state, setState] = useState(true);
  const theme = createTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const toggleDrawer = (open) => (event) => {
    console.log(event);
    if (!open) {
      setState(open);
    }
  };

  return (
    <>
      {!isLargeScreen ? (
        <SwipeableDrawerStyled
          className="otp-drawer"
          anchor="bottom"
          open={state}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          <Typography textAlign={'right'} pt={2} px={2}>
            <CancelRoundedIcon
              onClick={() => setShowotpForm(false)}
              fontSize="small"
              style={{ cursor: 'pointer', color: 'lightgrey' }}
            />
          </Typography>
          <Box width={'auto'} px={2} py={2} role="presentation">
            <Form buttonStyle={buttonStyle} details={details} setOtpStatus={setOtpStatus} />
          </Box>
        </SwipeableDrawerStyled>
      ) : (
        <TransitionsModal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={isLargeScreen && true}
          setShowotpForm={setShowotpForm}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Form buttonStyle={buttonStyle} details={details} setOtpStatus={setOtpStatus} />
        </TransitionsModal>
      )}
    </>
  );
}
