import React from 'react';
import { InputFields } from '../inputFields';

const InsuranceInputField = ({ register, errors, field }) => (
  <InputFields
    label={field?.label}
    type={field?.type}
    autoComplete="off"
    {...register(field?.key, {
      required: `${field?.label} is required`,
      pattern: { value: new RegExp(field?.validation), message: `Invalid ${field?.label}` },
    })}
    error={!!errors?.[`${field?.key}`]}
    helperText={errors?.[`${field?.key}`]?.message}
    disabled={!field?.editable}
    onInput={(e) => {
      e.target.value = e.target.value.slice(0, field?.max_length || 64);
    }}
  />
);

export default InsuranceInputField;
