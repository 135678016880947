export const KYC_DETAILS = 'KYC_DETAILS';
export const KYC_DETAILS_SUCCESS = 'KYC_DETAILS_SUCCESS';
export const KYC_DETAILS_ERROR = 'KYC_DETAILS_ERROR';

export const KYC_DETAILS_SUBMIT_FORM = 'KYC_DETAILS_SUBMIT_FORM';
export const KYC_DETAILS_SUBMIT_FORM_SUCCESS = 'KYC_DETAILS_SUBMIT_FORM_SUCCESS';
export const KYC_DETAILS_SUBMIT_FORM_ERROR = 'KYC_DETAILS_SUBMIT_FORM_ERROR';

export const SET_SCREEN_DETAIL = 'SET_SCREEN_DETAIL';

export const OKYC_DETAILS = 'OKYC_DETAILS';

export const OKYC_DETAILS_SUCCESS = 'OKYC_DETAILS_SUCCESS';
export const OKYC_DETAILS_ERROR = 'OKYC_DETAILS_ERROR';
export const SET_BUTTON_LOADING = 'SET_BUTTON_LOADING';

export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';

export const USER_DETAILS = 'USER_DETAILS';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_ERROR = 'USER_DETAILS_ERROR';

export const GET_CITY_NAME = 'GET_CITY_NAME';
export const GET_CITY_NAME_SUCCESS = 'GET_CITY_NAME_SUCCESS';
export const GET_CITY_NAME_ERROR = 'GET_CITY_NAME_ERROR';

export const GET_KYC_REDIRECT_URL = 'GET_KYC_REDIRECT_URL';
export const GET_KYC_REDIRECT_URL_SUCCESS = 'GET_KYC_REDIRECT_URL_SUCCESS';
export const GET_KYC_REDIRECT_URL_ERROR = 'GET_KYC_REDIRECT_URL_ERROR';
