import jwtDecode from 'jwt-decode';
import CryptoJS from 'crypto-js';
import { logEvent } from 'firebase/analytics';

import analytics from './fireBase';
import { SECRET_WEB_KEY } from './constant';

export const onHandleLink = (url, subUrl) => window.open(`${url}${subUrl}`);

export const logFirebaseEventFromWeb = (eventIdentifier, params) => {
  logEvent(analytics, eventIdentifier, params);
};

export const logFirebaseEventFromNativeInterface = (name, params) => {
  if (!name) return;

  if (window.AnalyticsWebInterface) {
    // Call Android interface
    window.AnalyticsWebInterface.logEvent(name, JSON.stringify(params));
  } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.firebase) {
    // Call iOS interface
    const message = { command: 'logEvent', name, parameters: params };
    window.webkit.messageHandlers.firebase.postMessage(message);
  } else {
    // No Android or iOS interface found
    console.log('No native APIs found.');
  }
};

export const setFirebaseUserPropertyFromNativeInterface = (name, value) => {
  if (!name || !value) return;

  if (window.AnalyticsWebInterface) {
    // Call Android interface
    window.AnalyticsWebInterface.setUserProperty(name, value);
  } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.firebase) {
    // Call iOS interface
    const message = { command: 'setUserProperty', name, value };
    window.webkit.messageHandlers.firebase.postMessage(message);
  } else {
    // No Android or iOS interface found
    console.log('No native APIs found.');
  }
};

export const isValidToken = () => {
  const customerToken = window.localStorage.getItem('customer_token');
  if (customerToken) {
    const validateToken = jwtDecode(customerToken && customerToken);
    if (!validateToken?.exp) return false;
    return new Date(validateToken.exp * 1000).getTime() - new Date().getTime() < 1;
  }
  return false;
};

export const getChecksumFormdata = (data) => {
  const payload = data;
  Object.keys(payload).forEach((keyValue) => {
    if (payload[keyValue] === '') {
      delete payload[keyValue];
    }
  });

  const getChecksum = (postDataObj) => {
    const params = new URLSearchParams(postDataObj);
    const str = params.toString();
    const decode = decodeURI(str);
    return decode;
  };
  const finalString = getChecksum(payload);
  const checksum = CryptoJS.HmacSHA256(finalString, SECRET_WEB_KEY).toString();
  return { ...payload, checksum };
};

export const generateChecksum = (body) => {
  const finalstring = Object.keys(body).reduce(
    (previous, key) => (previous ? `${previous}&${key}=${body[key]}` : `${key}=${body[key]}`),
    '',
  );

  return CryptoJS.HmacSHA256(finalstring, SECRET_WEB_KEY).toString();
};

export const bodyFormData = (payload) => {
  const formData = new FormData();
  Object.keys(payload).forEach((key) => {
    if (key === 'files') {
      payload[key].forEach((file) => formData.append('bankStmt[]', file));
    } else {
      formData.append(key, payload[key]);
    }
  });

  return formData;
};
