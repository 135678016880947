import { call, put, takeLatest } from 'redux-saga/effects';

import {
  TICKET_SUMMARY_GET_COMMENTS,
  TICKET_SUMMARY_GET_TICKETS,
  TICKET_SUMMARY_POST_COMMENTS,
  CURRENT_TICKET,
} from '../../../utils/constant';
import {
  getTicketFail,
  getTicketSuccess,
  getCommentsSuccess,
  getCommentsFail,
  postCommentSuccess,
  postCommentFail,
  saveTicketSuccess,
} from '../actions';
import { getTicketsApiCall, getTicketComments, postTicketComment } from '../services';

function* getTickets({ payload }) {
  try {
    const getTicketsResult = yield call(getTicketsApiCall, payload);
    if (getTicketsResult?.status === 200) {
      yield put(getTicketSuccess(getTicketsResult?.data));
    } else if (getTicketsResult?.data?.status === false || getTicketsResult?.data?.status === 'False') {
      yield put(getTicketFail(getTicketsResult?.message));
    }
  } catch (err) {
    yield put(getTicketFail('Something went wrong,Please try again after some time'));
  }
}

function* getComments({ payload }) {
  try {
    const getCommentsResult = yield call(getTicketComments, payload);
    if (getCommentsResult?.status === 200) {
      yield put(getCommentsSuccess(getCommentsResult?.data));
    } else if (getCommentsResult?.data?.status === false || getCommentsResult?.data?.status === 'False') {
      yield put(getCommentsFail(getCommentsResult?.message));
    }
  } catch (error) {
    yield put(getCommentsFail('Something went wrong,Please try again after some time'));
  }
}

function* postComments({ payload }) {
  try {
    const postCommentResult = yield call(postTicketComment, payload);
    if (postCommentResult?.data?.status === true) {
      yield put(postCommentSuccess(postCommentResult?.data));
    } else if (postCommentResult?.status === false) {
      yield put(postCommentFail(postCommentResult?.errors));
    }
  } catch (error) {
    yield put(postCommentFail(error));
  }
}

function* saveTicketId({ payload }) {
  yield put(saveTicketSuccess(payload));
}

export default function* TicketSummarySagas() {
  yield takeLatest(TICKET_SUMMARY_GET_TICKETS, getTickets);
  yield takeLatest(TICKET_SUMMARY_GET_COMMENTS, getComments);
  yield takeLatest(TICKET_SUMMARY_POST_COMMENTS, postComments);
  yield takeLatest(CURRENT_TICKET, saveTicketId);
}
