import {
  GET_COMMONDETAIL,
  GET_COMMONDETAIL_SUCCESS,
  GET_COMMONDETAIL_ERROR,
  POST_COMMONDETAIL,
  POST_COMMONDETAIL_SUCCESS,
  POST_COMMONDETAIL_ERROR,
} from '../../../utils/constant';

export const commonDetailsGet = (get) => ({
  type: GET_COMMONDETAIL,
  payload: get,
});

export const commonDetailsgetSuccess = (get) => ({
  type: GET_COMMONDETAIL_SUCCESS,
  payload: get,
});

export const commonDetailsgetFail = (error) => ({
  type: GET_COMMONDETAIL_ERROR,
  payload: error,
});

export const commonDetailsPost = (post) => ({
  type: POST_COMMONDETAIL,
  payload: post,
});

export const commonDetailsPostSuccess = (postSuccess) => ({
  type: POST_COMMONDETAIL_SUCCESS,
  payload: postSuccess,
});

export const commonDetailsPostFail = (postError) => ({
  type: POST_COMMONDETAIL_ERROR,
  payload: postError,
});
