import React from 'react';
import { Stack, useTheme, useMediaQuery } from '@mui/material';
import TypographyComp from './typographyComp';
import ProgressStatus from './progressStatus';
import GenerateNachInst from './generateNachInst';

const GenerateNachStatic = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Stack spacing={3}>
      {!isLargeScreen && <TypographyComp content="Autopay" variant="h6" fontWeight={700} />}
      <ProgressStatus />
      <TypographyComp
        content="Maximize you credit limit by placing standing instructions:"
        variant="body"
        fontWeight={600}
      />
      <GenerateNachInst />
    </Stack>
  );
};

export default GenerateNachStatic;
