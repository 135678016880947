/* eslint-disable max-lines */

import React, { useEffect, useState } from 'react';
import './Insurance.scss';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { faqs, blogs, banners, getIconByName, scrollToTop, handleReadMore, getAuthToken } from './insuranceData';
import Carousal from '../../components/sharedComponents/Carousal/Carousal';
import img1 from '../../assets/images/InsuranceNewHomepage/insurance Icons/Wallet.svg';
import img5 from '../../assets/images/InsuranceNewHomepage/insurance Icons/OTT.svg';
import img6 from '../../assets/images/InsuranceNewHomepage/insurance Icons/EMI.svg';
import img8 from '../../assets/images/InsuranceNewHomepage/insurance Icons/Pocket.svg';
import img12 from '../../assets/images/InsuranceNewHomepage/insurance Icons/Motor.svg';
import img14 from '../../assets/images/InsuranceNewHomepage/insurance Icons/HomeApp.svg';
import verticalLine from '../../assets/images/InsuranceNewHomepage/vertical.png';
import girl from '../../assets/images/InsuranceNewHomepage/beautiful-curly-girl-pointing-finger.png';
import ins_partner_img1 from '../../assets/images/InsuranceNewHomepage/insurancePartner1.png';
import ins_partner_img2 from '../../assets/images/InsuranceNewHomepage/insurancePartner2.png';
import ins_partner_img3 from '../../assets/images/InsuranceNewHomepage/digitLogo.png';
import ins_partner_img4 from '../../assets/images/InsuranceNewHomepage/insurancePartner4.png';
import smilingImg from '../../assets/images/InsuranceNewHomepage/smiling.png';
import Ellipse from '../../assets/images/InsuranceNewHomepage/Ellipse.png';
import downVector from '../../assets/images/InsuranceNewHomepage/down_vector.png';
import CustomPopup from '../../components/sharedComponents/customPopup/CustomPopup';
import InsuranceItems from './InsuranceItems';
import { sendEventToClevertap } from '../../actions/clevertapActions';
import ContextWrapper from '../GoldWaitlist/componentWrapper/context';

const BASE_REDIRECT_LINK = process.env.REACT_APP_REDIRECT_LINK_LIVE;
const MAGNETO_INSURANCE_API = process.env.REACT_APP_MAGNETO_INSURANCE_API;
function Insurance() {
  const [openIndices, setOpenIndices] = useState([]);
  const [openItemOptionsPopup, setOpenItemOptionsPopup] = useState(false);
  const [itemOptions, setItemOptions] = useState([]);
  const dispatch = useDispatch();
  const toggleFAQ = (index) => {
    if (openIndices.includes(index)) {
      setOpenIndices(openIndices.filter((i) => i !== index)); // Close FAQ
    } else {
      setOpenIndices([...openIndices, index]); // Open FAQ
    }
  };
  const [token, setToken] = useState('');
  useEffect(() => {
    const { authToken = '', deviceId = '' } = getAuthToken();
    if (authToken) localStorage.setItem('authToken', authToken);
    if (deviceId) localStorage.setItem('deviceId', deviceId);
    if (authToken) setToken(authToken);
    dispatch(
      sendEventToClevertap({
        auth_token: authToken,
        eventData: {
          event_name: 'Pageviewed',
          event_property: { pagename: 'insurance_landing_page', Product: 'Insurance' },
        },
      }),
    );
  }, []);
  const handleCancel = () => {
    setOpenItemOptionsPopup(false);
  };
  const handleItemClick = async (id, category) => {
    try {
      const response = await axios.get(`${MAGNETO_INSURANCE_API}sub-catagory/${id}`);
      setItemOptions(response.data.data);
      setOpenItemOptionsPopup(true);
      dispatch(
        sendEventToClevertap({
          auth_token: token,
          eventData: {
            event_name: 'Page_clicked',
            event_property: { Product: 'Insurance', Category: category, subCategory: '' },
          },
        }),
      );
    } catch (err) {
      setOpenItemOptionsPopup(false);
    }
  };
  const handleItemClickWithoutOptions = (id, category) => {
    if (id === 'income') {
      window.open(
        `${BASE_REDIRECT_LINK}/insurance/ZBRQ13?title=income-protection-insurance&auth_token=${token}`,
        '_blank',
      );
    } else if (id === 'travel') {
      window.open(`${BASE_REDIRECT_LINK}/insurance/P5G8Q1?title=travel-insurance&auth_token=${token}`, '_blank');
    } else if (id === 'loan') {
      window.open(
        `${BASE_REDIRECT_LINK}/insurance/3OPZCU?title=emi-protection-insurance&auth_token=${token}`,
        '_blank',
      );
    } else if (id === 'deals') {
      window.open(`${BASE_REDIRECT_LINK}/insurance/A0O00Z?title=deals-insurance&auth_token=${token}`, '_blank');
    } else if (id === 'pocket') {
      window.open(`${BASE_REDIRECT_LINK}/insurance/pocket-insurance&auth_token=${token}`, '_blank');
    } else if (id === 'critical') {
      window.open(
        `${BASE_REDIRECT_LINK}/insurance/RSCIFH?title=critical-illness-insurance&auth_token=${token}`,
        '_blank',
      );
    } else if (id === 'child') {
      window.open(
        `${BASE_REDIRECT_LINK}/insurance/6JKUZT?title=child-education-insurance&auth_token=${token}`,
        '_blank',
      );
    } else if (id === 'home') {
      window.open(`${BASE_REDIRECT_LINK}/insurance/W1QO20?title=home-insurance&auth_token=${token}`, '_blank');
    } else if (id === 'upi') {
      window.open(
        `${BASE_REDIRECT_LINK}/insurance/M7R9X2?title=upi-fraud-protection-insurance&auth_token=${token}`,
        '_blank',
      );
    } else if (id === 'device') {
      window.open(
        `${BASE_REDIRECT_LINK}/insurance/S8ANQG?title=home-appliance-insurance&auth_token=${token}`,
        '_blank',
      );
    }
    dispatch(
      sendEventToClevertap({
        auth_token: token,
        eventData: {
          event_name: 'Page_clicked',
          event_property: { Product: 'Insurance', Category: category, subCategory: '' },
        },
      }),
    );
  };
  return (
    <ContextWrapper>
      <div className="insurance_homepage">
        <div className="insurance_homepage_inner">
          <div className="redLineDiv"></div>
          <div className="carousalDiv">
            <Carousal bannerData={banners} />
          </div>
          <InsuranceItems
            handleItemClick={handleItemClick}
            handleItemClickWithoutOptions={handleItemClickWithoutOptions}
          />
          <div className="redLineDiv" style={{ marginTop: '6em' }}></div>
          <div className="whyChooseDiv">
            <div className="girlImg">
              <img src={girl}></img>
            </div>
            <div className="whyChooseTextDiv">
              <span className="chooseTextHeading">Why choose Stashfin for Insurance</span>
              <span className="chooseTextDesc longChoose">
                Choose us for peace of mind. With trust, reliability, and tailored solutions, we're more than insurance
                –we're family. Experience unmatched support and stability for a brighter tomorrow.
              </span>
              <span className="chooseTextDesc shortChoose">
                Choose us for peace of mind. With trust, reliability, and tailored solutions.
              </span>
              <div className="chooseTextPointsDiv">
                <div className="chooseTextPointsInnerDiv">
                  <span className="choseTextPoint">
                    No Paper <br></br> Work
                  </span>
                  <img src={verticalLine} className="vertical"></img>
                  <span className="choseTextPoint">
                    Simple <br></br> Process
                  </span>
                  <img src={verticalLine} className="vertical"></img>
                  <span className="choseTextPoint">
                    Lower <br></br> Prize
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="topSelling">
            <div className="topSellingTextDiv">
              <span className="topSellingHeading longHeading">Top selling insurance plans</span>
              <span className="topSellingHeading shortHeading">Top selling plans</span>
              <span className="topSellingDesc longTop">
                Discover peace of mind with our top-selling insurance plans. Comprehensive coverage, personalized
                options, and unwavering security. Safeguard your future today for a brighter tomorrow.
              </span>
              <span className="topSellingDesc shortTop">
                Discover peace of mind with our top-selling insurance plans.
              </span>
            </div>
            <div className="topSellingPropsDiv">
              <div className="topSellingPropsInnerDiv1">
                <div
                  className="topSellingProp"
                  onClick={() => {
                    handleItemClick('9JE9ND7A');
                  }}
                >
                  <img src={img1} className="top_prop_img"></img>
                  <span className="top_prop_text">
                    Wallet <br></br>Protection
                  </span>
                </div>
                <div
                  className="topSellingProp"
                  onClick={() => {
                    handleItemClick('M00X291E');
                  }}
                >
                  <img src={img5} className="top_prop_img"></img>
                  <span className="top_prop_text">
                    OTT+ <br></br>Insurance
                  </span>
                </div>
                <div
                  className="topSellingProp"
                  onClick={() => {
                    handleItemClickWithoutOptions('loan');
                  }}
                >
                  <img src={img6} className="top_prop_img"></img>
                  <span className="top_prop_text">Hospicash</span>
                </div>
              </div>
              <div className="topSellingPropsInnerDiv2">
                <div
                  className="topSellingProp"
                  onClick={() => {
                    handleItemClickWithoutOptions('pocket');
                  }}
                >
                  <img src={img8} className="top_prop_img"></img>
                  <span className="top_prop_text">
                    Pocket <br></br>Insurance
                  </span>
                </div>
                <div
                  className="topSellingProp"
                  onClick={() => {
                    handleItemClickWithoutOptions('device');
                  }}
                >
                  <img src={img14} className="top_prop_img"></img>
                  <span className="top_prop_text long">
                    Device <br></br>Protection
                  </span>
                  <span className="top_prop_text short">
                    Device <br></br>Protection
                  </span>
                </div>
                <div
                  className="topSellingProp"
                  onClick={() => {
                    handleItemClick('13FSLC3Q');
                  }}
                >
                  <img src={img12} className="top_prop_img"></img>
                  <span className="top_prop_text">
                    Motor <br></br>Insurance
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="insur_partner">
            <span className="ins_partner_heading">Our Insurance partners</span>
            <div className="ins_partner_Imgs_div">
              <img src={ins_partner_img1} className="ins_partner_img"></img>
              <img src={ins_partner_img2} className="ins_partner_img"></img>
              <img src={ins_partner_img3} className="ins_partner_img"></img>
              <img src={ins_partner_img4} className="ins_partner_img"></img>
            </div>
          </div>
          <div className="redLineDiv" style={{ marginTop: '5em' }}></div>
          <div className="smiling_div">
            <div className="smileImg">
              <img src={smilingImg}></img>
            </div>
            <div className="smilingTextDiv">
              <span className="smilingTextHeading">We have 10 million+ smiling faces</span>
              <span className="smilingTextDesc longSmilingText">
                Join our satisfied users who trust us for peace of mind.
                <br></br>Experience reliable coverage and exceptional service.
              </span>
              <span className="smilingTextDesc shortSmilingText">
                Join our satisfied users who trust us for peace of mind.
              </span>
              <button className="BuyBtn" onClick={scrollToTop}>
                Buy now
              </button>
            </div>
          </div>
          <div className="blogs">
            <span className="blogs_heading">Our Blogs</span>
            <div className="blogs_outer_container">
              <div className="blog_div_all">
                {blogs.map((b, idx) => (
                  <div className="blog_div" key={idx}>
                    <img className="blog_img" src={b.imgSrc}></img>
                    <div className="details_div">
                      <span className="date">{b.date}</span>
                      <img className="dot" src={Ellipse}></img>
                      <span className="byWho">{b.by}</span>
                    </div>
                    <span className="blog_heading">{b.heading}</span>
                    <span className="blog_desc web">
                      {b.content}...
                      <span
                        className="readMoreBlog"
                        onClick={() => {
                          handleReadMore(idx);
                        }}
                      >
                        Read more
                      </span>
                    </span>
                    <span className="blog_desc mobile">
                      {b.content.substring(0, 60)}...
                      <span
                        className="readMoreBlog"
                        onClick={() => {
                          handleReadMore(idx);
                        }}
                      >
                        Read more
                      </span>
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="FAQ">
            <div className="faq_header_div">
              <span className="faq_haeding">FAQ</span>
              <div className="redLineDiv" style={{ marginTop: '0px' }}></div>
            </div>
            <div className="faq_content_div">
              {faqs.map((faq, index) => (
                <div className="faq_ques_ans_div" key={index}>
                  <div className="faq_ques_div">
                    <span className="faq_ques">{faq.question}</span>
                    <img
                      src={downVector}
                      className={`
                      ${openIndices.includes(index) ? 'down_vector_faq up' : 'down_vector_faq'}
                        `}
                      onClick={() => toggleFAQ(index)}
                    ></img>
                  </div>
                  {openIndices.includes(index) && (
                    <div className="faq_ans_div">
                      <div dangerouslySetInnerHTML={{ __html: faq.answer }} className="faq_ans" />
                      {/* <span className="faq_ans">{faq.answer}</span> */}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          {openItemOptionsPopup && (
            <CustomPopup
              headerText=""
              onClick={() => {
                setOpenItemOptionsPopup(!openItemOptionsPopup);
              }}
              onCancel={handleCancel}
              btnText="Blog"
              showFooterBtns={false}
            >
              <div className="itemOptionsContent">
                <span className="itemOptionTitle">
                  {itemOptions?.title !== '' ? itemOptions?.title : 'Wellness Insurance'}
                </span>
                <div className="itemOptions">
                  {itemOptions?.sub_categories?.map((opt) => (
                    <div
                      className="option"
                      onClick={() => {
                        const url = `${opt.action}&auth_token=${token}`;
                        // const [urlBeforeTitle, urlAfterTitle] = url.split('title=');
                        // const urlWithToken = `${urlBeforeTitle}auth_token=${token}&title=${urlAfterTitle}`;
                        window.open(url, '_blank');
                        dispatch(
                          sendEventToClevertap({
                            auth_token: token,
                            eventData: {
                              event_name: 'Page_clicked',
                              event_property: {
                                Product: 'Insurance',
                                Category: itemOptions?.title !== '' ? itemOptions?.title : 'Wellness Insurance',
                                subCategory: opt.name,
                              },
                            },
                          }),
                        );
                      }}
                    >
                      <img src={getIconByName(opt.name)}></img>
                      <span>{opt.name}</span>
                    </div>
                  ))}
                  {itemOptions?.title === '' && (
                    <div
                      className="option"
                      onClick={() => {
                        handleItemClickWithoutOptions('loan');
                        dispatch(
                          sendEventToClevertap({
                            auth_token: token,
                            eventData: {
                              event_name: 'Page_clicked',
                              event_property: {
                                Product: 'Insurance',
                                Category: 'Wellness Insurance',
                                subCategory: 'Hospicash',
                              },
                            },
                          }),
                        );
                      }}
                    >
                      <img src={img6}></img>
                      <span>Hospicash</span>
                    </div>
                  )}
                </div>
              </div>
            </CustomPopup>
          )}
        </div>
      </div>
    </ContextWrapper>
  );
}
export default Insurance;
