import React from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import GenerateNachStatic from './components/generateNachStatic';
import CliBankDetails from './components/cliBankDetails';
import CreditLimitImageSection from './components/creditLimitImageSection';
import CliNachButtonContainer from './components/cliNachButtonContainer';
import EnachLink from './components/enachLink';

const GenerateNach = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      {isLargeScreen ? (
        <Box display="flex" justifyContent="space-between">
          <Box px={6} pb={6} width="50%" backgroundColor="#F4F4F7">
            <CreditLimitImageSection />
          </Box>
          <Box width="50%" backgroundColor="#EAEAEA" display="flex" justifyContent="center">
            <Box
              backgroundColor="#FFFFFF"
              margin={'auto'}
              borderRadius={'12px'}
              px={5}
              py={3}
              mx={10}
              my={2}
              textAlign={isLargeScreen && 'center'}
            >
              <GenerateNachStatic />
              <CliBankDetails />
              <CliNachButtonContainer />
              <EnachLink />
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <Box px={2} py={2.5}>
            <GenerateNachStatic />
            <CliBankDetails />
            <CliNachButtonContainer />
            <EnachLink />
          </Box>
        </>
      )}
    </>
  );
};

export default GenerateNach;
