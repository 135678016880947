export const buttonStyle = {
  borderRadius: '10px',
  backgroundColor: 'rgba(2, 0, 107, 1)',
  fontWeight: 600,
  ':hover': { backgroundColor: 'rgba(2, 0, 107, 0.7)' },
  '&.Mui-disabled': {
    backgroundColor: 'rgba(2, 0, 107, 0.8)',
    color: 'white',
  },
  textTransform: 'none',
};

export const buttonStyle2 = {
  borderRadius: '10px',
  backgroundColor: 'rgba(170, 1, 63, 1)',
  fontWeight: 600,
  ':hover': { backgroundColor: 'rgba(170, 1, 63, 0.9)' },
  '&.Mui-disabled': {
    backgroundColor: 'darkgrey',
    color: 'white',
  },
  textTransform: 'none',
};
