import { CLI_SEND_OTP, CLI_VERIFY_MOBILE, CLOSE_EVENT, LOAD_EVENT, VERIFY_CLI } from './constants';

export const verifyMobileCli = (payload) => ({
  type: CLI_VERIFY_MOBILE,
  payload,
});

export const sendOtpCli = (payload) => ({
  type: CLI_SEND_OTP,
  payload,
});

export const verifyCli = (payload) => ({
  type: VERIFY_CLI,
  payload,
});

export const closeEvent = (payload) => ({
  type: CLOSE_EVENT,
  payload,
});

export const loadEvent = (payload) => ({
  type: LOAD_EVENT,
  payload,
});

export const successEventLoad = (payload) => ({
  type: 'SUCCESS_EVENT',
  payload,
});
