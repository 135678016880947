import React from 'react';

// import material-ui components
import { Box, Typography, createTheme, useMediaQuery, Backdrop, Modal } from '@mui/material';

// import assets
import OtpErrorImage from '../../../assets/images/insuranceJourneyImages/otpErrorImage.png';

import '../insurance.scss';

const style = {
  position: 'absolute',
  margin: '10px 0px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  boxShadow: 24,
  p: 4,
  border: 'none',
};

const OTPError = ({ text, description }) => {
  const theme = createTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const handleClose = () => {
    window.location.href = 'https://www.stashfin.com';
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isLargeScreen && true}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style} className="otp_error_container">
          <img src={OtpErrorImage} alt="error icon" />
          <Typography>{text}</Typography>
          <Typography>{description}</Typography>
        </Box>
      </Modal>

      {/* Mobile view */}

      <Box className="mobile_otp_error_container">
        <img src={OtpErrorImage} alt="error icon" />
        <Typography>{text}</Typography>
        <Typography>{description}</Typography>
      </Box>
    </>
  );
};

export default OTPError;
