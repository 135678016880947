import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';

const UnCoverageTypo = () => {
  const { productDetailsInfo } = useSelector((state) => state.InsuranceReducer);

  const { metadata } = productDetailsInfo.whats_not_covered;
  return (
    <Typography variant="body2" fontWeight={600} sx={{ mb: { xs: 0, sm: 2, md: 2, lg: 2 } }}>
      {metadata?.title}
    </Typography>
  );
};

export default UnCoverageTypo;
