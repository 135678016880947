import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import { successEventLoad } from '../Redux/actions';
import { buttonStyle } from './utils';

const CliNachButtonContainer = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const navigate = useNavigate();
  const redirectFunc = () => navigate('/cli');
  const handleClick = (callBack) => {
    dispatch(successEventLoad());
    callBack();
  };
  return (
    <Box mt={isLargeScreen ? 4 : 6}>
      <Button variant="contained" fullWidth size="large" sx={buttonStyle} onClick={() => handleClick(redirectFunc)}>
        Generate enach
      </Button>
    </Box>
  );
};

export default CliNachButtonContainer;
