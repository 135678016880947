import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  GET_ALL_GRIP_BONDS,
  GET_ALL_GRIP_BONDS_FAILURE,
  GET_ALL_GRIP_BONDS_SUCCESS,
  GET_BOND_DETAILS,
  GET_BOND_DETAILS_FAILURE,
  GET_BOND_DETAILS_SUCCESS,
  GET_GRIP_REDIRECTION_LINK,
  GET_GRIP_REDIRECTION_LINK_FAILURE,
  GET_GRIP_REDIRECTION_LINK_SUCCESS,
  GET_ORDERS,
  GET_ORDERS_FAILURE,
  GET_ORDERS_SUCCESS,
  GET_USER_CONSENT_STATUS,
  GET_USER_CONSENT_STATUS_FAILURE,
  GET_USER_CONSENT_STATUS_SUCCESS,
  REDEEM_NOW,
  REDEEM_NOW_FAILURE,
  REDEEM_NOW_SUCCESS,
  REGISTER_USER_TO_GRIP,
  REGISTER_USER_TO_GRIP_FAILURE,
  REGISTER_USER_TO_GRIP_SUCCESS,
  SET_CURRENT_SCREEN,
  SET_STASHFIN_APPROVE_STATUS,
} from './actionTypes';
import {
  getAllGripBondsApiCall,
  getBondDetailsApiCall,
  getOrdersApiCall,
  getRedirectionLinkApiCall,
  redeemNowApiCall,
  registerUserToGripApiCall,
  userConsentStatusApiCall,
} from './services';

const commonError = 'Something went wrong, please try again later';
const stateValue = (state) => state.WealthReducer;

function* getUserConsentStatus() {
  try {
    const response = yield call(userConsentStatusApiCall);
    if (response.status === 200) {
      yield put({ type: GET_USER_CONSENT_STATUS_SUCCESS, payload: response.data.data });
      if (response.data.data.gripUserConsent) {
        yield put({ type: SET_CURRENT_SCREEN, payload: 'bonds' });
      } else if (
        (response.data.data.firstName === null ||
          response.data.data.firstName.length === 0 ||
          response.data.data.lastName === null ||
          response.data.data.lastName.length === 0 ||
          response.data.data.email === null ||
          response.data.data.email.length === 0 ||
          response.data.data.phone === null) &&
        !response.data.data.gripUserConsent
      ) {
        yield put({ type: SET_STASHFIN_APPROVE_STATUS, payload: false });
      }
    } else if (response.status > 200 && response.status < 500) {
      yield put({
        type: GET_USER_CONSENT_STATUS_FAILURE,
        payload: { error: response?.data?.error_message || response?.data?.message || commonError },
      });
    } else {
      yield put({ type: GET_USER_CONSENT_STATUS_FAILURE, payload: { error: commonError } });
    }
  } catch (err) {
    yield put({ type: GET_USER_CONSENT_STATUS_FAILURE, payload: { error: commonError } });
  }
}

function* registerUserToGrip() {
  const { userBasicDetails } = yield select(stateValue);
  try {
    const response = yield call(registerUserToGripApiCall, userBasicDetails);
    if (response.status === 200) {
      if (response.data.status === 'SUCCESS' || response.data.status === 'SUCEESS') {
        yield put({ type: REGISTER_USER_TO_GRIP_SUCCESS, payload: true });
      } else {
        yield put({
          type: REGISTER_USER_TO_GRIP_FAILURE,
          payload: { error: response?.data?.message || commonError },
        });
      }
    } else if (response.status > 200 && response.status < 500) {
      yield put({
        type: REGISTER_USER_TO_GRIP_FAILURE,
        payload: { error: response?.data?.error_message || response?.data?.message || commonError },
      });
    } else {
      yield put({ type: REGISTER_USER_TO_GRIP_FAILURE, payload: { error: commonError } });
    }
  } catch (err) {
    yield put({ type: REGISTER_USER_TO_GRIP_FAILURE, payload: { error: commonError } });
  }
}

function* getAllGripBonds() {
  try {
    const response = yield call(getAllGripBondsApiCall);
    if (response.status === 200) {
      yield put({ type: GET_ALL_GRIP_BONDS_SUCCESS, payload: response?.data });
    } else if (response.status > 200 && response.status < 500) {
      yield put({
        type: GET_ALL_GRIP_BONDS_FAILURE,
        payload: { error: response?.data?.error_message || response?.data?.message || commonError },
      });
    } else {
      yield put({ type: GET_ALL_GRIP_BONDS_FAILURE, payload: { error: commonError } });
    }
  } catch (err) {
    yield put({ type: GET_ALL_GRIP_BONDS_FAILURE, payload: { error: commonError } });
  }
}

function* getBondDetails() {
  const { bondId } = yield select(stateValue);
  try {
    const response = yield call(getBondDetailsApiCall, { bondId });
    if (response.status === 200) {
      yield put({ type: GET_BOND_DETAILS_SUCCESS, payload: response?.data?.msg?.assetInfo });
    } else if (response.status > 200 && response.status < 500) {
      yield put({
        type: GET_BOND_DETAILS_FAILURE,
        payload: { error: response?.data?.error_message || response?.data?.message || commonError },
      });
    } else {
      // yield put({ type: GET_BOND_DETAILS_FAILURE, payload: { error: commonError } });
      yield put({ type: GET_BOND_DETAILS_SUCCESS, payload: { name: 'simran', gender: 'F' } });
    }
  } catch (err) {
    yield put({ type: GET_BOND_DETAILS_FAILURE, payload: { error: commonError } });
  }
}

function* getRedirectionLink() {
  const { bondId } = yield select(stateValue);
  try {
    const response = yield call(getRedirectionLinkApiCall, { bondId });
    if (response.status === 200) {
      const link = response?.data?.data?.redirectUrl ?? '';
      yield put({ type: GET_GRIP_REDIRECTION_LINK_SUCCESS, payload: link });
      if (link !== '') {
        window.open(link, '_self');
      }
    } else if (response.status > 200 && response.status < 500) {
      yield put({
        type: GET_GRIP_REDIRECTION_LINK_FAILURE,
        payload: { error: response?.data?.error_message || response?.data?.message || commonError },
      });
    } else {
      yield put({ type: GET_GRIP_REDIRECTION_LINK_FAILURE, payload: { error: commonError } });
    }
  } catch (err) {
    yield put({ type: GET_GRIP_REDIRECTION_LINK_FAILURE, payload: { error: commonError } });
  }
}

function* getOrders() {
  try {
    const response = yield call(getOrdersApiCall);
    if (response.status === 200) {
      yield put({ type: GET_ORDERS_SUCCESS, payload: response?.data?.data?.orders ?? [] });
    } else if (response.status > 200 && response.status < 500) {
      yield put({
        type: GET_ORDERS_FAILURE,
        payload: { error: response?.data?.error_message || response?.data?.message || commonError },
      });
    } else {
      yield put({ type: GET_ORDERS_FAILURE, payload: { error: commonError } });
    }
  } catch (err) {
    yield put({ type: GET_ORDERS_FAILURE, payload: { error: commonError } });
  }
}

function* redeemNow({ payload }) {
  try {
    const response = yield call(redeemNowApiCall, { payload });
    if (response.status === 200) {
      yield put({ type: REDEEM_NOW_SUCCESS, payload: true });
    } else if (response.status > 200 && response.status < 500) {
      yield put({
        type: REDEEM_NOW_FAILURE,
        payload: { error: response?.data?.error || commonError, status: false },
      });
    } else {
      yield put({ type: REDEEM_NOW_FAILURE, payload: { error: commonError } });
    }
  } catch (err) {
    yield put({ type: REDEEM_NOW_FAILURE, payload: { error: commonError } });
  }
}

export default function* WealthSaga() {
  yield takeLatest(GET_USER_CONSENT_STATUS, getUserConsentStatus);
  yield takeLatest(REGISTER_USER_TO_GRIP, registerUserToGrip);
  yield takeLatest(GET_ALL_GRIP_BONDS, getAllGripBonds);
  yield takeLatest(GET_BOND_DETAILS, getBondDetails);
  yield takeLatest(GET_GRIP_REDIRECTION_LINK, getRedirectionLink);
  yield takeLatest(GET_ORDERS, getOrders);
  yield takeLatest(REDEEM_NOW, redeemNow);
}
