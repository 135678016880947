import React from 'react';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FeedbackForm from './feedbackForm';
import { useElements } from '../../ContextWrapper/insuranceContextWrapper';
import { feedbackEventTrigger } from '../../Redux/actions';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(4),
  },
  '& .MuiPaper-root': {
    width: '482px',
    borderRadius: '12px',
  },
}));

const FeedbackModal = () => {
  const { setBackbuttonPress } = useElements();

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(feedbackEventTrigger(false));
    setBackbuttonPress(false);
  };

  return (
    <div>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={true}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        <DialogContent dividers>
          <FeedbackForm handleModalClose={handleClose} />
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default FeedbackModal;
