import React from 'react';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import TypographyComp from './typographyComp';

const EnachLink = () => (
  <Box textAlign="center" mt={2}>
    <Link to="https://www.google.com" target="_blank" style={{ textDecorationColor: 'rgba(0,0,102,1)' }}>
      <TypographyComp content="Know more about eNACH" variant="caption" fontWeight={500} color="#006" />
    </Link>
  </Box>
);

export default EnachLink;
