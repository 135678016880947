import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { Box, Button, Container, Stack, Typography, useMediaQuery, createTheme, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

import { InputFields } from '../../../InsuranceJourney/Components/inputFields';
import { requestOtp } from '../../services';

export default function TicketForm({ buttonStyle, setShowotpForm, setDetails, details }) {
  const navigate = useNavigate();
  const theme = createTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const [attachment, setAttachment] = useState(null);
  const [attachmentValue, setAttachmentValue] = useState(null);
  const [loading, setLoading] = useState();
  const [error, setError] = useState('');

  const headingStyle = isLargeScreen
    ? {
        fontSize: '20px',
        marginBottom: '20px',
        textAlign: 'center',
      }
    : {
        fontSize: '24px',
        marginBottom: '30px',
      };

  const form = useForm({
    defaultValues: {
      name: details.name || '',
      address: details.address || '',
      phone: details.phone || '',
      reference_number: details.reference_number || '',
      description: details.description || '',
      email: details.email || '',
      loan_id: details.loan_id || '',
      customer_id: details.customer_id || '',
    },
  });

  const { register, handleSubmit, formState } = form;

  const { errors } = formState;

  const onSubmit = async (data) => {
    const { name, address, phone, description, email, loan_id, customer_id } = data;
    setDetails({
      name,
      address,
      phone,
      description,
      attachment,
      email,
      loan_id,
      customer_id,
    });
    setLoading(true);
    const response = await requestOtp(data.phone);
    setLoading(false);
    if (response.status === 200) {
      setShowotpForm(true);
    } else if (response.status > 200 && response.status < 500) {
      if (response.status === 404) {
        navigate('/customer-services');
      } else {
        setError(response?.data?.error);
      }
    } else {
      setError('Something went wrong');
    }
  };

  return (
    <Container sx={{ paddingTop: '30px' }}>
      <Typography sx={headingStyle} fontWeight={600}>
        Customer Grievance Form
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack spacing={2}>
          <InputFields
            label="Full Name *"
            type="text"
            autoComplete="off"
            {...register('name', { required: 'Full Name is required' })}
            error={!!errors.name}
            helperText={errors.name?.message}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^a-zA-Z ]/gi, '').slice(0, 32);
            }}
          />
          <InputFields
            label="Email *"
            type="email"
            autoComplete="off"
            {...register('email', {
              required: 'Email is required',
              pattern: { value: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/, message: 'Invalid email' },
            })}
            error={!!errors.email}
            helperText={errors.email?.message}
            disabled={details?.email !== ''}
          />
          <InputFields
            label="Address"
            type="text"
            autoComplete="off"
            {...register('address')}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^a-zA-Z ]/gi, '').slice(0, 48);
            }}
          />
          <InputFields
            label="Mobile Number *"
            type="number"
            autoComplete="off"
            {...register('phone', {
              required: { value: true, message: 'Mobile Number is required' },
            })}
            error={!!errors.phone}
            helperText={errors.phone?.message}
            onInput={(e) => {
              e.target.value = e.target.value.slice(0, 10);
            }}
          />
          <InputFields label="Loan ID" type="number" autoComplete="off" {...register('loan_id')} />
          <InputFields label="Customer ID" type="number" autoComplete="off" {...register('customer_id')} />
          <InputFields
            label="Issue Details *"
            type="text"
            {...register('description', {
              required: { value: true, message: 'Details required' },
            })}
            error={!!errors.description}
            helperText={errors.description?.message}
            autoComplete="off"
          />

          <label htmlFor="attachment">
            <input
              type="file"
              id="attachment"
              accept="image/png, image/jpg, image/jpeg"
              style={{ display: 'none' }}
              onChange={(e) => {
                setAttachment(e.target.files[0]);
                setAttachmentValue(e.target.value);
              }}
            />
            <Box
              sx={{
                border: '1px solid #E2E2E2',
                padding: '16.5px 14px',
                borderRadius: '10px',
                color: '#696B6E',
                fontSize: '14px',
                fontWeight: '400',
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              {!attachmentValue ? (
                <>
                  <FileUploadOutlinedIcon /> Add Attachment
                </>
              ) : (
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <Typography sx={{ color: '#02006B' }}>{attachmentValue.split('\\').pop()}</Typography>
                  <Typography onClick={() => setAttachment(null)}>
                    <CloseIcon />
                  </Typography>
                </div>
              )}
            </Box>
          </label>
          <Box mb={2}>
            <Typography color="error" variant="caption">
              {error}
            </Typography>
          </Box>
          <Box textAlign={'center'}>
            <Button
              fullWidth
              variant="contained"
              size="large"
              type="submit"
              sx={buttonStyle}
              endIcon={loading ? <CircularProgress size="20px" color="inherit" /> : ''}
            >
              Next
            </Button>
          </Box>
        </Stack>
      </form>
    </Container>
  );
}
