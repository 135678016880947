import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, createTheme, useMediaQuery } from '@mui/material';
import SimpleBackdrop from '../../components/sharedComponents/backdropLoader/loader';
import TransitionsModal from './Modals/desktopTransitionModal';
import BasicInfoOtp from './Components/basicInfoOtp';
import InsuranceDetails from './insuranceDetails';
import { toggleBasicInfo, toggleLoader } from './Redux/actions';
import './insurance.scss';
import { productTitleMap } from './Components/utils';

const RequestOtp = () => {
  const theme = createTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const { loader, productDetailsInfo, showBasicInfo } = useSelector((state) => state.InsuranceReducer);
  const { data } = productDetailsInfo?.product_details || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const productId = window.localStorage.getItem('insuranceProductId');
    if (Object.keys(productDetailsInfo).length === 0) {
      const titleName = productTitleMap[productId]?.toLowerCase() || 'insurance';
      const queryParams = new URLSearchParams({ title: titleName });
      navigate(`/insurance/${productId}?${queryParams.toString()}`);
    } else if (!showBasicInfo) {
      dispatch(toggleBasicInfo({ show: true }));
    } else {
      dispatch(toggleLoader({ loader: false }));
    }
  }, []);

  if (loader && Object.keys(productDetailsInfo).length !== 0) {
    return (
      <>
        <InsuranceDetails />
        <Container>
          <SimpleBackdrop open={true} />
        </Container>
      </>
    );
  }

  if (loader && Object.keys(productDetailsInfo).length === 0) {
    return (
      <Container>
        <SimpleBackdrop open={true} />
      </Container>
    );
  }

  return (
    <>
      {isLargeScreen && (
        <>
          <InsuranceDetails />
          <TransitionsModal>
            <BasicInfoOtp terms={data.terms_and_conditions} />
          </TransitionsModal>
        </>
      )}
      {!isLargeScreen && <InsuranceDetails payNow={false} />}
    </>
  );
};

export default RequestOtp;
