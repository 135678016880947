import React, { useState } from 'react';

import { Container, createTheme, useMediaQuery } from '@mui/material';

import CreateTicketForm from './component/createTicketForm';
import OTPForm from './component/otpForm';
import OtpStatus from './component/otpStatus';
import TicketHedaers from './component/header';

const ModalStyle = {
  position: 'absolute',
  margin: '10px 0px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  boxShadow: 24,
  py: 4,
  px: 2,
};

const buttonStyle = {
  backgroundColor: '#265DB3',
  borderRadius: '20px',
};

const CreateTicket = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const prefilledEmail = urlParams.get('additional_detail');
  const [showOtpForm, setShowotpForm] = useState(false);
  const [otpStatus, setOtpStatus] = useState(null);
  const [details, setDetails] = useState({
    name: '',
    address: '',
    phone: '',
    description: '',
    attachment: '',
    email: prefilledEmail || '',
    loan_id: '',
    customer_id: '',
  });

  const theme = createTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Container>
      {isLargeScreen && <TicketHedaers />}
      {(!showOtpForm || (!isLargeScreen && otpStatus !== 'verified')) && (
        <CreateTicketForm
          ModalStyle={ModalStyle}
          setShowotpForm={setShowotpForm}
          buttonStyle={buttonStyle}
          setDetails={setDetails}
          details={details}
        />
      )}
      {showOtpForm && otpStatus !== 'verified' && (
        <OTPForm
          ModalStyle={ModalStyle}
          buttonStyle={buttonStyle}
          details={details}
          setOtpStatus={setOtpStatus}
          setShowotpForm={setShowotpForm}
        />
      )}
      {otpStatus === 'verified' && <OtpStatus ModalStyle={ModalStyle} buttonStyle={buttonStyle} />}
    </Container>
  );
};

export default CreateTicket;
