/* eslint-disable max-len */
/* eslint-disable max-statements */
import axios from 'axios';
import moment from 'moment';

const ADMIN_PANEL_URL = process.env.REACT_APP_ADMIN_PANEL_API;

export const decodeCustomerId = async ({ payload }) => {
  try {
    const response = await axios.post(`${ADMIN_PANEL_URL}ticket/decode-customer-id/`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const createIvrTicket = async ({ payload }) => {
  const { customerId, concern, concernDetails, attachment, preferredTime } = payload;
  const reqData = new FormData();
  reqData.append('user_id', customerId);
  reqData.append('topic_id', concern);
  reqData.append('message', concernDetails);
  if (preferredTime) reqData.append('preferred_time', moment(preferredTime).format('YYYY-MM-DDTHH:mm:ss[Z]'));
  if (attachment) reqData.append('attachment', attachment);
  try {
    const response = await axios.post(`${ADMIN_PANEL_URL}ticket/registered-user/create-ticket/`, reqData);
    return response;
  } catch (error) {
    return error.response;
  }
};
