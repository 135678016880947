import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, SwipeableDrawer } from '@mui/material';
import { styled } from '@mui/material/styles';
import OtpForm from './otpForm';

import '../insurance.scss';

const SwipeableDrawerStyled = styled(SwipeableDrawer)({
  '& .MuiDrawer-paper': {
    borderRadius: '20px 20px 0px 0px',
  },
});

const OtpDrawer = () => {
  const [state, setState] = useState(true);
  const navigate = useNavigate();

  const toggleDrawer = (open) => (event) => {
    console.log(event);
    if (!open) {
      setState(open);
      navigate(-1);
    }
  };

  return (
    <SwipeableDrawerStyled
      className="otp-drawer"
      anchor="bottom"
      open={state}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      <Box width={'auto'} px={2} py={2} role="presentation">
        <OtpForm />
      </Box>
    </SwipeableDrawerStyled>
  );
};

export default OtpDrawer;
