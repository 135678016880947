import { Helmet } from 'react-helmet-async';
import { Container } from '@mui/material';
import KeyCoverageDetails from './keyCoverageDetails';
import ProductDetails from './productDetails';
import CoverageDetails from './coverageDetails';
import UnCoverageDetails from './uncoverageDetails';
import LinksAndDocuments from './linksAndDocument';

import './insurance.scss';

const InsuranceDetails = ({ payNow }) => (
  <Container className="main-container">
    <Helmet>
      <title>Insurance</title>
    </Helmet>
    <ProductDetails payNow={payNow} />
    <KeyCoverageDetails />
    <CoverageDetails />
    <UnCoverageDetails />
    <LinksAndDocuments />
  </Container>
);

export default InsuranceDetails;
