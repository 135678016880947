import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Stack, Typography, Box, useMediaQuery, createTheme, Modal, Backdrop, Button } from '@mui/material';

import LOGO from '../../../../assets/images/otp_submit.png';

const OtpStatus = ({ ModalStyle, buttonStyle }) => {
  const navigate = useNavigate();
  const theme = createTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  return isLargeScreen ? (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isLargeScreen && true}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box sx={ModalStyle}>
        <Box py={2} px={2} textAlign="center" display="flex" justifyContent="center">
          <Stack spacing={3} display="flex" justifyContent="center">
            <Box>
              <img src={LOGO} style={{ height: '80px', width: '80px' }} />
            </Box>
            <Typography fontWeight={600} color="#000066">
              Request Submitted
            </Typography>
            <p>Our customer support team will reach out to you within 24 hours</p>
            <Button
              fullWidth
              variant="contained"
              size="large"
              type="submit"
              sx={buttonStyle}
              onClick={() => navigate('/')}
            >
              Back to Home
            </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  ) : (
    <Box py={2} px={2} textAlign="center" display="flex" justifyContent="center" height="80vh">
      <Stack spacing={2} display="flex" justifyContent="center">
        <Box>
          <img src={LOGO} style={{ height: '50px', width: '50px' }} />
        </Box>
        <Typography fontWeight={600} sx={{ color: '#000066' }}>
          Request Submitted
        </Typography>
        <p>Our customer support team will reach out to you within 24 hours</p>
      </Stack>
    </Box>
  );
};

export default OtpStatus;
