import axios from 'axios';

import { get } from '../../../service';
import { GET_COMMONDETAIL_URL_PARAM } from '../../../utils/constant';
import { ErrorMessage } from '../../../utils/responseMessage';

export const commonDetailApiCall = async () => {
  try {
    const req = {
      api: process.env.REACT_APP_BASE_V3_API_URL,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'client-token': window.localStorage.getItem('customer_token'),
      },
    };

    const response = await get(GET_COMMONDETAIL_URL_PARAM, req);
    if (response.status === true) {
      return response;
    }
    if (response.status === false) {
      return response;
    }
    return response;
  } catch (e) {
    ErrorMessage({
      message: e,
    });
    return e.response;
  }
};

export const postCommonDetailApiCall = async (payload) => {
  try {
    const response = await axios.post(window.localStorage.getItem('api'), payload, {
      headers: {
        'Content-Type': 'application/json',
        'client-token': window.localStorage.getItem('customer_token'),
      },
    });
    if (response.status === true) {
      return response;
    }
    if (response.status === false) {
      return response;
    }
    return response;
  } catch (error) {
    return error.response;
  }
};
