// eslint-disable-next-line import/no-extraneous-dependencies
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import OtpInput from 'react-otp-input';
import { Box, Button, CircularProgress, Typography } from '@mui/material';

import { buttonStyle } from './buttonStyle';
import { getOtp, togglePaynow, verifyOtp } from '../Redux/actions';
import '../insurance.scss';
import { logFirebaseEventFromWeb } from '../../../utils/helper';
import { productTitleMap } from './utils';

const OtpStyles = {
  textAlign: 'center',
  margin: '10px 17px',
  height: '60px',
  width: '60px',
  border: '1px solid lightgrey',
  borderRadius: '10px',
};

const OtpForm = () => {
  const { otpForm, otpFormError, basicInfoOtpDetails, showPaynow } = useSelector((state) => state.InsuranceReducer);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [otp, setOtp] = useState('');
  const [counter, setCounter] = useState(59);

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    if (showPaynow) {
      const productId = window.localStorage.getItem('insuranceProductId');
      const titleName = productTitleMap[productId]?.toLowerCase() || 'insurance';
      const queryParams = new URLSearchParams({ title: titleName });
      navigate(`/insurance/paynow/${productId}?${queryParams.toString()}`);
    }
    return () => dispatch(togglePaynow({ show: false }));
  }, [showPaynow]);

  const handleOTP = () => {
    logFirebaseEventFromWeb('insurance_action_verify_otp_click');
    const payload = {
      otpPaylaod: {
        otp: Number(otp),
        mobile: basicInfoOtpDetails.userDetails?.mobile,
        name: basicInfoOtpDetails.userDetails?.name,
        product_uid: window.localStorage.getItem('insuranceProductId'),
      },
      loader: true,
    };
    dispatch(verifyOtp(payload));
  };

  const handleResendOtp = () => {
    const payload = {
      userDetails: basicInfoOtpDetails.userDetails,
      otpError: '',
      loader: true,
    };
    dispatch(getOtp(payload));
    setCounter(59);
  };

  return (
    <>
      <Typography my={2} variant="h6" fontWeight={600} textAlign={'center'}>
        OTP Verification
      </Typography>
      <Typography my={2} variant="body2" fontWeight={400} textAlign={'center'}>
        OTP has been sent to {basicInfoOtpDetails.userDetails.mobile}
      </Typography>

      <Box className="otp-fields-container" my={4}>
        <OtpInput
          value={otp}
          shouldAutoFocus={true}
          inputType="number"
          placeholder="----"
          inputStyle={OtpStyles}
          focusStyle={{ outline: 'none', border: '1px solid red' }}
          onChange={setOtp}
          numInputs={4}
          renderInput={(props) => <input {...props} />}
        />
      </Box>
      <Box mb={3} textAlign={'center'}>
        {counter > 0 ? (
          <Typography variant="body2" color={'rgba(32, 32, 32, 0.40)'}>
            Resend Otp in <span style={{ color: '#006' }}> {counter}</span> sec
          </Typography>
        ) : (
          <Typography
            variant="body2"
            fontWeight={500}
            color={'#006'}
            style={{ cursor: 'pointer' }}
            onClick={handleResendOtp}
          >
            Resend OTP
          </Typography>
        )}
      </Box>
      <Box mb={2}>
        <Typography color="error" variant="caption">
          {otpFormError}
        </Typography>
      </Box>
      <Box textAlign={'center'}>
        <Button
          fullWidth
          variant="contained"
          size="large"
          type="submit"
          sx={buttonStyle}
          onClick={handleOTP}
          disabled={otp.length !== 4 || otpForm.loader}
          endIcon={otpForm?.loader ? <CircularProgress size="20px" color="inherit" /> : ''}
        >
          Verify
        </Button>
      </Box>
    </>
  );
};

export default OtpForm;
