import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Stack,
  Button,
  CircularProgress,
} from '@mui/material';
import { feedbackEventTrigger, postFeedbackAction } from '../../Redux/actions';
import { InputFields } from '../inputFields';
import { buttonStyle } from '../buttonStyle';
import { useElements } from '../../ContextWrapper/insuranceContextWrapper';

const FeedbackForm = () => {
  const { setBackbuttonPress } = useElements();
  const dispatch = useDispatch();
  const { feedbackData, feedbackLoader } = useSelector((state) => state.InsuranceReducer);
  const [feedbackState, setFeedbackState] = useState({
    takeFeedback: false,
    showButton: false,
    feedback: '',
    feedbackResponseCode: '',
  });

  const handleChange = (event) => {
    const { value } = event.target;

    if (value === '5') {
      setFeedbackState((prev) => ({ ...prev, takeFeedback: true, showButton: true, feedbackResponseCode: +value }));
    } else {
      setFeedbackState((prev) => ({ ...prev, takeFeedback: false, showButton: true, feedbackResponseCode: +value }));
    }
  };
  const handleFeedbackChange = (e) => {
    setFeedbackState((prev) => ({ ...prev, feedback: e.target.value }));
  };

  const handleClose = () => {
    dispatch(feedbackEventTrigger(false));
    setBackbuttonPress(false);
  };

  const handleFeedback = () => {
    const payload = {
      question_id: feedbackData?.question?.id,
      response: feedbackState?.feedbackResponseCode,
      remarks: feedbackState?.feedback,
    };

    dispatch(postFeedbackAction(payload, setBackbuttonPress));
  };

  return (
    <Box>
      <Stack spacing={3}>
        <Box>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              <Typography fontWeight={600} fontSize="16px" color="black" mb={2}>
                {feedbackData?.question?.text}
              </Typography>
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              fontSize="small"
              name="radio-buttons-group"
              onChange={handleChange}
              //   value={value}
            >
              <Stack spacing={1.5}>
                {feedbackData?.options?.map(({ id, text }) => (
                  <FormControlLabel
                    id={text}
                    value={id}
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: '#D6D6D6',
                          '&.Mui-checked': {
                            color: '#000066',
                          },
                        }}
                      />
                    }
                    label={
                      <Typography variant="body2" color="black" fontWeight={500}>
                        {text}
                      </Typography>
                    }
                  />
                ))}
              </Stack>
            </RadioGroup>
          </FormControl>
        </Box>
        {feedbackState?.takeFeedback && (
          <Box pl={1}>
            <InputFields
              id="filled-multiline-flexible"
              fullWidth
              multiline
              rows={3}
              placeholder="Share your valuable feedback and help us improve"
              sx={{
                fontWeight: 'bold',
                '& input': {
                  fontWeight: 500,
                },
              }}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^a-zA-Z ]/gi, '');
              }}
              onChange={handleFeedbackChange}
              value={feedbackState?.feedback}
            />
          </Box>
        )}
        {feedbackState?.showButton && (
          <Box textAlign={'center'}>
            <Button
              fullWidth
              variant="contained"
              size="large"
              type="submit"
              sx={{
                ...buttonStyle,
                borderRadius: '98px',
                '&.Mui-disabled': {
                  backgroundColor: '#D6D6D6',
                  color: 'white',
                },
              }}
              onClick={handleFeedback}
              disabled={(feedbackState?.feedback?.length === 0 && feedbackState?.takeFeedback) || feedbackLoader}
              endIcon={feedbackLoader ? <CircularProgress size="20px" color="inherit" /> : ''}
            >
              Submit
            </Button>
          </Box>
        )}
        <Box>
          <Typography
            color="#000066"
            variant="body2"
            textAlign="center"
            fontWeight={500}
            onClick={handleClose}
            sx={{ cursor: 'pointer' }}
          >
            Ask me later
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default FeedbackForm;
