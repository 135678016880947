import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useForm } from 'react-hook-form';
import { Stack, Button, Typography, MenuItem, Box, CircularProgress } from '@mui/material';
import { InputFields } from './inputFields';
import { buttonStyle } from './buttonStyle';
import { getOtp, toggleVeriftOtp } from '../Redux/actions';
import ShowMoreText from './showMoreText';
import { logFirebaseEventFromWeb } from '../../../utils/helper';

const BasicInfoOtp = ({ terms }) => {
  const { otpError, basicInfoOtpDetails, showVerifyOtp } = useSelector((state) => state.InsuranceReducer);
  const { mobile, name, gender } = basicInfoOtpDetails.userDetails;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (showVerifyOtp) {
      navigate('/insurance/verify-otp');
    }
    return () => dispatch(toggleVeriftOtp({ show: false }));
  }, [showVerifyOtp]);

  const form = useForm({
    defaultValues: {
      mobile: mobile || '',
      name: name || '',
      gender: gender || '',
    },
  });

  const { register, handleSubmit, formState } = form;

  const { errors } = formState;

  const onSubmit = (data) => {
    logFirebaseEventFromWeb('insurance_action_proceed');
    const payload = {
      userDetails: { ...data, mobile: Number(data.mobile) },
      otpError: '',
      loader: true,
    };
    dispatch(getOtp(payload));
  };
  return (
    <Box className="basic-info-otp">
      <Typography variant="h6" fontWeight={700} textAlign={'center'} mb={3}>
        Basic Info
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack spacing={2}>
          <InputFields
            select
            label="Salutation"
            autoComplete="off"
            defaultValue={gender}
            {...register('gender', { required: 'Salutation is required' })}
            error={!!errors.gender}
            helperText={errors.gender?.message}
          >
            <MenuItem value="male">Mr.</MenuItem>
            <MenuItem value="female">Mrs.</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </InputFields>
          <InputFields
            label="Full Name"
            type="text"
            autoComplete="off"
            {...register('name', {
              required: 'Full Name is required',
              pattern: { value: /^[^\s]+[A-Za-z\s]+$/i, message: 'Invalid Name' },
            })}
            error={!!errors.name}
            helperText={errors.name?.message}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^a-zA-Z ]/gi, '').slice(0, 32);
            }}
          />
          <InputFields
            label="Mobile Number"
            type="number"
            autoComplete="off"
            {...register('mobile', {
              required: { value: true, message: 'Mobile is required' },
              pattern: { value: /(6|7|8|9)\d{9,}/, message: 'Invalid mobile number' },
            })}
            error={!!errors.mobile}
            helperText={errors.mobile?.message}
            onInput={(e) => {
              e.target.value = e.target.value.slice(0, 10);
            }}
          />
          <ShowMoreText terms={terms} />
          {otpError && (
            <Box>
              <Typography variant="caption" color="error">
                {otpError}
              </Typography>
            </Box>
          )}
          <Box textAlign={'center'}>
            <Button
              variant="contained"
              size="large"
              type="submit"
              sx={buttonStyle}
              fullWidth
              endIcon={basicInfoOtpDetails?.loader ? <CircularProgress size="20px" color="inherit" /> : ''}
              disabled={basicInfoOtpDetails?.loader}
            >
              Proceed
            </Button>
          </Box>
        </Stack>
      </form>
    </Box>
  );
};

export default BasicInfoOtp;
