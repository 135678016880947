import React from 'react';
import { Box } from '@mui/material';
import NotifyBellImage from '../../../assets/images/cliImages/notify-bell.png';
import ProgressMeterImage from '../../../assets/images/cliImages/progress-meter.png';
import DollarThumbImage from '../../../assets/images/cliImages/dollar-thumb-up.png';
import TypographyComp from './typographyComp';

const GenerateNachInst = () => {
  const data = [
    { icon: NotifyBellImage, text: 'No annoying reminders & late fees' },
    { icon: DollarThumbImage, text: 'Auto debit of EMIs' },
    { icon: ProgressMeterImage, text: 'Healthy credit score' },
  ];
  return (
    <Box>
      {data?.map(({ icon, text }, ind) => (
        <Box
          display="flex"
          alignItems="center"
          key={text}
          py={2}
          borderBottom={ind !== data.length - 1 ? '1px solid rgba(0, 0, 0, 0.05)' : ''}
        >
          <Box>
            <img src={icon} alt={text} />
          </Box>
          <Box pl={2}>
            <TypographyComp content={text} variant="body2" fontWeight={500} color="rgba(0, 0, 0, 0.70)" />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default GenerateNachInst;
