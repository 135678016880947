import React from 'react';

import OwnHome from '../../assets/images/home-animation.webp';
import OwnCar from '../../assets/images/car-travel.webp';
import TravelPower from '../../assets/images/traveler-walking.webp';
import OwnBusiness from '../../assets/images/women-working.webp';

export default function CreditLine() {
  return (
    <div className="CreditLine">
      <div className="container">
        <div className="row">
          <div className="col-12 mb-md-5">
            <h2>
              Get more powerful with <span>Stashfin Credit Line!</span>
            </h2>
          </div>
          <div className="col-md-6 md-mb-5">
            <img src={OwnHome} className="HomeAnimation" alt="Stashfin Credit Line!" />
            <h3>
              Get power to <br />
              own a home
            </h3>
          </div>
          <div className="col-md-6 md-mb-5">
            <img src={OwnCar} className="RoundedAnimation" alt="Car Travel | Stashfin" />
            <h3>
              Get power to
              <br /> own a car
            </h3>
          </div>
          <div className="col-md-6">
            <img src={TravelPower} className="TravelerAnimation" alt="Traveller | Stashfin" />
            <h3>
              Get power to travel
              <br /> where you want
              <span>with Instant Travel Loan</span>
            </h3>
          </div>
          <div className="col-md-6">
            <img src={OwnBusiness} className="RoundedAnimation" alt="Personal Loan for Business | Stashfin" />
            <h3>
              Get power start to
              <br />
              your own business
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}
