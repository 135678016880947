/* eslint-disable complexity */
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  LOAN_RESTRUCTURE_DETAILS,
  LOAN_RESTRUCTURE_DETAILS_ERROR,
  LOAN_RESTRUCTURE_DETAILS_SUCCESS,
  LOAN_RESTRUCTURE_RESEND_SEND_OTP,
  LOAN_RESTRUCTURE_SEND_OTP,
  LOAN_RESTRUCTURE_SEND_OTP_ERROR,
  LOAN_RESTRUCTURE_SEND_OTP_SUCCESS,
  VERIFY_LOAN_RESTRUCTURE,
  VERIFY_LOAN_RESTRUCTURE_ERROR,
  VERIFY_LOAN_RESTRUCTURE_SUCCESS,
} from './constants';
import { loanRestrSendOtpApi, loanRestructureDetailFetchApi, verifyLoanRestrApi } from './services';

const commonError = 'Something went wrong, please try again later';

const stateValue = (state) => state.LoanRestructureReducer;

function* onFetchLoanRestrDetails() {
  try {
    const { token } = yield select(stateValue);
    const response = yield call(loanRestructureDetailFetchApi, token);
    if (response.status === 200) {
      yield put({ type: LOAN_RESTRUCTURE_DETAILS_SUCCESS, payload: response.data?.data });
    } else if (response.status > 200 && response.status < 500) {
      yield put({
        type: LOAN_RESTRUCTURE_DETAILS_ERROR,
        payload: { flag: 'error', error: response?.data?.error_message },
      });
    } else {
      yield put({ type: LOAN_RESTRUCTURE_DETAILS_ERROR, payload: { flag: 'error', error: commonError } });
    }
  } catch (err) {
    yield put({ type: LOAN_RESTRUCTURE_DETAILS_ERROR, payload: { flag: 'error', error: commonError } });
  }
}

function* onSendOtpLR() {
  try {
    const { token } = yield select(stateValue);
    const response = yield call(loanRestrSendOtpApi, token);
    if (response.status === 200) {
      yield put({ type: LOAN_RESTRUCTURE_SEND_OTP_SUCCESS, payload: { flag: 1 } });
    } else if (response.status > 200 && response.status < 500) {
      yield put({
        type: LOAN_RESTRUCTURE_SEND_OTP_ERROR,
        payload: { flag: 'error', error: response?.data?.error_message },
      });
    } else {
      yield put({ type: LOAN_RESTRUCTURE_SEND_OTP_ERROR, payload: { flag: 'error', error: commonError } });
    }
  } catch (err) {
    yield put({ type: LOAN_RESTRUCTURE_SEND_OTP_ERROR, payload: { flag: 'error', error: commonError } });
  }
}

function* onVerifyLoanRestr({ payload }) {
  try {
    const { token } = yield select(stateValue);
    const response = yield call(verifyLoanRestrApi, payload, token);
    if (response.status === 200) {
      yield put({
        type: VERIFY_LOAN_RESTRUCTURE_SUCCESS,
        payload: { flag: 'success', message: response?.data?.data || commonError },
      });
    } else if (response.status === 404) {
      yield put({
        type: VERIFY_LOAN_RESTRUCTURE_ERROR,
        payload: response?.data?.error_message,
      });
    } else {
      yield put({
        type: VERIFY_LOAN_RESTRUCTURE_SUCCESS,
        payload: { flag: 'success', message: response?.data?.error_message || commonError },
      });
    }
  } catch (err) {
    yield put({
      type: VERIFY_LOAN_RESTRUCTURE_SUCCESS,
      payload: { flag: 'success', message: commonError },
    });
  }
}

export default function* LoanRestructureSagas() {
  yield takeLatest(LOAN_RESTRUCTURE_DETAILS, onFetchLoanRestrDetails);
  yield takeLatest(LOAN_RESTRUCTURE_SEND_OTP, onSendOtpLR);
  yield takeLatest(LOAN_RESTRUCTURE_RESEND_SEND_OTP, onSendOtpLR);
  yield takeLatest(VERIFY_LOAN_RESTRUCTURE, onVerifyLoanRestr);
}
