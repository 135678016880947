import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, createTheme, useMediaQuery } from '@mui/material';
import SimpleBackdrop from '../../components/sharedComponents/backdropLoader/loader';
import InsuranceDetails from './insuranceDetails';
import TransitionsModal from './Modals/desktopTransitionModal';
import OtpForm from './Components/otpForm';
import OtpDrawer from './Components/otpDrawer';
import { toggleBasicInfo, toggleLoader } from './Redux/actions';
import OTPError from './Components/otpError';
import { productTitleMap } from './Components/utils';

const VerifyOtp = () => {
  const theme = createTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const { loader, productDetailsInfo, showBasicInfo, flag } = useSelector((state) => state.InsuranceReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const productId = window.localStorage.getItem('insuranceProductId');
    if (Object.keys(productDetailsInfo).length === 0) {
      const titleName = productTitleMap[productId]?.toLowerCase() || 'insurance';
      const queryParams = new URLSearchParams({ title: titleName });
      navigate(`/insurance/${productId}?${queryParams.toString()}`);
    } else if (!showBasicInfo) {
      dispatch(toggleBasicInfo({ show: true }));
    } else {
      dispatch(toggleLoader({ loader: false }));
    }
  }, []);
  if (loader && Object.keys(productDetailsInfo).length !== 0) {
    return (
      <>
        <InsuranceDetails />
        <Container>
          <SimpleBackdrop open={true} />
        </Container>
      </>
    );
  }

  if (loader && Object.keys(productDetailsInfo).length === 0) {
    return (
      <Container>
        <SimpleBackdrop open={true} />
      </Container>
    );
  }

  if (flag === 'not_registered') {
    return (
      <OTPError
        text="Number not Registered"
        description="Sorry, you are currently not eligible for this 
      product as the Mobile number provided is not registered with us"
      />
    );
  }
  if (flag === 'not_eligible') {
    return (
      <OTPError
        text="Customer not registered"
        description="Sorry, you are currently not eligible for this product 
      as You are not a registered Stashfin Customer"
      />
    );
  }

  return (
    <>
      {isLargeScreen && (
        <>
          <InsuranceDetails />
          <TransitionsModal>
            <OtpForm />
          </TransitionsModal>
        </>
      )}
      {!isLargeScreen && (
        <>
          {' '}
          <InsuranceDetails payNow={false} />
          <OtpDrawer />
        </>
      )}
    </>
  );
};

export default VerifyOtp;
