export const SET_AUTH = 'SET_AUTH';
export const RESET_LOADER = 'RESET_LOADER';
export const SET_FLAG = 'SET_FLAG';
export const SET_BUY_NOW = 'SET_BUY_NOW';
export const SET_OTP_DRAWER = 'SET_OTP_DRAWER';
export const GET_PRODUCT_DETAILS = 'GET_PRODUCT_DETAILS';
export const GET_PRODUCT_DETAILS_SUCCESS = 'GET_PRODUCT_DETAILS_SUCCESS';
export const GET_PRODUCT_DETAILS_ERROR = 'GET_PRODUCT_DETAILS_ERROR';
export const GET_OTP = 'GET_OTP';
export const GET_OTP_SUCCESS = 'GET_OTP_SUCCESS';
export const GET_OTP_ERROR = 'GET_OTP_ERROR';
export const VERIFY_OTP = 'VERIFY_OTP';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_ERROR = 'VERIFY_OTP_ERROR';
export const GET_PAYMENT_LINK = 'GET_PAYMENT_LINK';
export const GET_PAYMENT_LINK_SUCCESS = 'GET_PAYMENT_LINK_SUCCESS';
export const GET_PAYMENT_LINK_ERROR = 'GET_PAYMENT_LINK_ERROR';
export const GET_PAYMENT_STATUS = 'GET_PAYMENT_STATUS';
export const GET_PAYMENT_STATUS_SUCCESS = 'GET_PAYMENT_STATUS_SUCCESS';
export const GET_PAYMENT_STATUS_ERROR = 'GET_PAYMENT_STATUS_ERROR';
export const GET_PAYMENT_STATUS_FAILED = 'GET_PAYMENT_STATUS_FAILED';
export const GET_PAYMENT_STATUS_PENDING = 'GET_PAYMENT_STATUS_PENDING';
export const GET_PAYMENT_STATUS_LOADING = 'GET_PAYMENT_STATUS_LOADING';
export const CUSTOMER_NOT_REGISTERED = 'CUSTOMER_NOT_REGISTERED';
export const CUSTOMER_NOT_ELIGIBLE = 'CUSTOMER_NOT_ELIGIBLE';
export const GET_CREATE_APPLICATION = 'GET_CREATE_APPLICATION';
export const GET_CREATE_APPLICATION_SUCCESS = 'GET_CREATE_APPLICATION_SUCCESS';
export const GET_CREATE_APPLICATION_ERROR = 'GET_CREATE_APPLICATION_ERROR';
export const LOADER_TOGGLE = 'LOADER_TOGGLE';
export const BASIC_INFO = 'BASIC_INFO';
export const VERIFY_OTP_TOGGLE = 'VERIFY_OTP_TOGGLE';
export const PAYNOW_TOGGLE = 'PAYNOW_TOGGLE';
export const RETRY_PAYMENT = 'RETRY_PAYMENT';
export const RETRY_PAYMENT_SUCCESS = 'RETRY_PAYMENT_SUCCESS';
export const RETRY_PAYMENT_ERROR = 'RETRY_PAYMENT_ERROR';
export const OTP_BYPASS = 'OTP_BYPASS';
export const INSURANCE_FEEDBACK = 'INSURANCE_FEEDBACK';
export const INSURANCE_FEEDBACK_SUCCESS = 'INSURANCE_FEEDBACK_SUCCESS';
export const INSURANCE_FEEDBACK_ERROR = 'INSURANCE_FEEDBACK_ERROR';
export const FEEDBACK_EVENT_TRIGGER = 'FEEDBACK_EVENT_TRIGGER';
export const POST_FEEDBACK = 'POST_FEEDBACK';
export const POST_FEEDBACK_SUCCESS = 'POST_FEEDBACK_SUCCESS';
export const POST_FEEDBACK_ERROR = 'POST_FEEDBACK_ERROR';
export const USER_UPDATED_INFO = 'USER_UPDATED_INFO';
export const USER_UPDATED_INFO_SUCCESS = 'USER_UPDATED_INFO_SUCCESS';
export const USER_UPDATED_INFO_ERROR = 'USER_UPDATED_INFO_ERROR';
export const UPDATE_FIELD_INFO = 'UPDATE_FIELD_INFO';
export const UPDATE_FIELD_INFO_SUCCESS = 'UPDATE_FIELD_INFO_SUCCESS';
export const UPDATE_FIELD_INFO_ERROR = 'UPDATE_FIELD_INFO_ERROR';
export const GET_INSURANCE_PRODUCTS = 'GET_INSURANCE_PRODUCTS';
export const GET_INSURANCE_PRODUCTS_SUCCESS = 'GET_INSURANCE_PRODUCTS_SUCCESS';
export const GET_INSURANCE_PRODUCTS_ERROR = 'GET_INSURANCE_PRODUCTS_ERROR';
export const GET_INSURANCE_PRODUCTS_CATEGORY = 'GET_INSURANCE_PRODUCTS_CATEGORY';
export const GET_INSURANCE_PRODUCTS_CATEGORY_SUCCESS = 'GET_INSURANCE_PRODUCTS_CATEGORY_SUCCESS';
export const GET_INSURANCE_PRODUCTS_CATEGORY_ERROR = 'GET_INSURANCE_PRODUCTS_CATEGORY_ERROR';
export const GET_INSURANCE_LISTING = 'GET_INSURANCE_LISTING';
export const GET_INSURANCE_LISTING_SUCCESS = 'GET_INSURANCE_LISTING_SUCCESS';
export const GET_INSURANCE_LISTING_ERROR = 'GET_INSURANCE_LISTING_ERROR';
export const INSURANCE_STASHCASH_LEAD = 'INSURANCE_STASHCASH_LEAD';
export const INSURANCE_STASHCASH_LEAD_SUCCESS = 'INSURANCE_STASHCASH_LEAD_SUCCESS';
export const INSURANCE_STASHCASH_LEAD_ERROR = 'INSURANCE_STASHCASH_LEAD_ERROR';
export const INSURANCE_STASHCASH_BANNER = 'INSURANCE_STASHCASH_BANNER';
export const INSURANCE_STASHCASH_BANNER_SUCCESS = 'INSURANCE_STASHCASH_BANNER_SUCCESS';
export const INSURANCE_STASHCASH_BANNER_ERROR = 'INSURANCE_STASHCASH_BANNER_ERROR';
export const GET_LEADERBOARD = 'GET_LEADERBOARD';
export const GET_LEADERBOARD_SUCCESS = 'GET_LEADERBOARD_SUCCESS';
export const GET_LEADERBOARD_ERROR = 'GET_LEADERBOARD_ERROR';
