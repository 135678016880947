import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, SwipeableDrawer } from '@mui/material';
import { styled } from '@mui/material/styles';
import FeedbackForm from './feedbackForm';
import { useElements } from '../../ContextWrapper/insuranceContextWrapper';
import { feedbackEventTrigger } from '../../Redux/actions';

const SwipeableDrawerStyled = styled(SwipeableDrawer)({
  '& .MuiDrawer-paper': {
    borderRadius: '20px 20px 0px 0px',
  },
});

const FeedbackDrawer = () => {
  const { setBackbuttonPress } = useElements();
  const dispatch = useDispatch();

  const toggleDrawer = (open) => () => {
    console.log(open);
    dispatch(feedbackEventTrigger(false));
    setBackbuttonPress(false);
  };

  return (
    <SwipeableDrawerStyled className="otp-drawer" anchor="bottom" open={true} onClose={toggleDrawer()}>
      <Box width={'auto'} px={2} py={2} role="presentation">
        <FeedbackForm />
      </Box>
    </SwipeableDrawerStyled>
  );
};

export default FeedbackDrawer;
