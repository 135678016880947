import { DEAL_AND_OFFER, DEAL_AND_OFFER_SUCCESS, DEAL_AND_OFFER_ERROR } from '../../../utils/constant';

export const dealaAndOffer = (posts) => ({
  type: DEAL_AND_OFFER,
  payload: posts,
});
export const dealaAndOfferSuccess = (posts) => ({
  type: DEAL_AND_OFFER_SUCCESS,
  payload: posts,
});
export const dealaAndOfferFail = (error) => ({
  type: DEAL_AND_OFFER_ERROR,
  payload: error,
});
