import React, { createContext, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';

const UseContextWrapper = createContext();

export function useElements() {
  return useContext(UseContextWrapper);
}

const ContextWrapper = ({ children }) => {
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const token = urlParams.get('auth_token');
  const deviceId = urlParams.get('device_id');
  const utm_source = urlParams.get('utm_source');
  const utm_medium = urlParams.get('utm_medium');
  const utm_campaign = urlParams.get('utm_campaign');
  const utmData = { utm_source, utm_medium, utm_campaign };
  const reducer = useSelector((state) => state.GoldWaitlistReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <UseContextWrapper.Provider value={{ navigate, isLargeScreen, dispatch, reducer, token, deviceId, utmData }}>
      {children}
    </UseContextWrapper.Provider>
  );
};

export default ContextWrapper;
