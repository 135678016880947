import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, createTheme, useMediaQuery } from '@mui/material';
import TypographyComp from './typographyComp';
import CliProgressCircle from '../../../assets/images/cliImages/cli-progress.png';
import CliMutedCircle from '../../../assets/images/cliImages/cli-muted.png';
import CliSuccessCircle from '../../../assets/images/cliImages/cli-success.png';

const ProgressStatus = () => {
  const theme = createTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const { pathname } = useLocation();

  return (
    <Box>
      <Box
        textAlign={isLargeScreen && 'center'}
        sx={{ backgroundColor: '#F8F8F8', margin: 'auto' }}
        display="flex"
        justifyContent="space-around"
        width={isLargeScreen ? '295px' : '100%'}
        py={1.2}
        px={3}
        borderRadius={2}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box>
            <img src={pathname === '/cli/nach' ? CliSuccessCircle : CliProgressCircle} />
          </Box>
          <TypographyComp content="STEP 1" fontSize="8px" color="rgba(0, 0, 0, 0.40)" fontWeight={500} mt={1.3} />
          <TypographyComp
            content="Verify Phone"
            fontSize="10px"
            color={pathname === '/cli/nach' && 'rgba(0, 0, 0, 0.40)'}
            fontWeight={500}
          />
        </Box>
        <Box
          display="flex"
          borderRadius={1}
          alignItems={'center'}
          sx={{ height: '2px', width: '70px', backgroundColor: '#E2E2E2' }}
          mt={1.7}
        />
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box>
            <img src={pathname === '/cli/nach' ? CliProgressCircle : CliMutedCircle} />
          </Box>
          <TypographyComp content="STEP 2" fontSize="8px" color="rgba(0, 0, 0, 0.40)" fontWeight={500} mt={1.3} />
          <TypographyComp
            content="Set up Autopay"
            fontSize="10px"
            color={pathname !== '/cli/nach' && 'rgba(0, 0, 0, 0.40)'}
            fontWeight={500}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ProgressStatus;
