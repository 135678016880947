import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';

const ShowMoreText = ({ terms }) => {
  const [showText, setShowText] = useState(false);
  const handleText = () => setShowText(!showText);
  return (
    <Box>
      {!showText && (
        <Typography variant="caption" color={'rgba(0,0,0,0.6)'} fontWeight={400}>
          *by clicking on "Proceed", I agree with the{' '}
          <a href={terms} style={{ textDecoration: 'none' }} target="_blank" alt="terms and condition">
            Terms & Conditions{' '}
          </a>
          <Typography variant="caption" color={'primary'} style={{ cursor: 'pointer' }} onClick={handleText}>
            Show more..
          </Typography>
        </Typography>
      )}
      {showText && (
        <Typography variant="caption" color={'rgba(0,0,0,0.6)'} fontWeight={400}>
          *by Clicking on “Proceed”, I agree with the{' '}
          <a href={terms} style={{ textDecoration: 'none' }} target="_blank" alt="terms and condition">
            Terms & Conditions
          </a>{' '}
          governing the product/policy. I have read, understood and agree to the entire text, features & disclosures as
          mentioned in policy/membership wording & wish to buy this product. Product price is inclusive of GST (18%){' '}
          <Typography variant="caption" color={'primary'} style={{ cursor: 'pointer' }} onClick={handleText}>
            Show less
          </Typography>
        </Typography>
      )}
    </Box>
  );
};

export default ShowMoreText;
