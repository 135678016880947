import axios from 'axios';

const api = process.env.REACT_APP_ADMIN_PANEL_API;

export const requestOtp = async (phone) => {
  const data = {
    phone,
  };

  try {
    const response = await axios.post(`${api}ticket/non-registered/request-otp/`, data);
    return response;
  } catch (err) {
    return err?.response;
  }
};

export const createTicket = async (data) => {
  const reqData = new FormData();
  const { name, phone, address, description, attachment, otp, email, loan_id, customer_id } = data;
  reqData.append('name', name);
  reqData.append('phone', phone);
  reqData.append('address', address);
  reqData.append('description', description);
  if (attachment !== null && attachment !== undefined) {
    reqData.append('attachment', attachment);
  }
  reqData.append('otp', otp);
  reqData.append('email', email);
  reqData.append('loan_id', loan_id);
  reqData.append('customer_id', customer_id);

  try {
    const response = await axios.post(`${api}ticket/non-registered/create-ticket/`, reqData);
    return response;
  } catch (err) {
    return err?.response;
  }
};
