import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const colors = {
  themeColor: 'rgb(19,45,102)',
  greyColor: '#696B6E',
  lightGreyColor: '#E2E2E2',
  blackColor: '#000;',
  whiteColor: '#fff',
};

export const InputFields = styled(TextField)(() => ({
  '& input': {
    color: `${colors.blackColor}`,
    fontWeight: 500,
  },
  '& label.Mui-focused': {
    color: '#02006B',
    fontSize: '14px',
    fontWeight: '600',
  },
  '& label': {
    color: `${colors.greyColor}`,
    marginLeft: '8px',
    fontSize: '14px',
    fontWeight: '400',
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: `${colors.whiteColor}`,
    borderRadius: '10px',
    '&.Mui-focused fieldset': {
      borderRadius: '10px',
      border: '1px solid #02006B',
      legend: {
        marginLeft: '5px',
      },
      color: `${colors.themeColor}`,
    },
    '& fieldset': {
      borderRadius: '10px',
      border: `1px solid ${colors.lightGreyColor}`,
      legend: {
        marginLeft: '5px',
      },
    },
    '&.Mui-disabled fieldset': {
      borderRadius: '10px',
      border: `1px solid ${colors.lightGreyColor}`,
      legend: {
        marginLeft: '5px',
      },
    },
  },
}));
