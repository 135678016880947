import axios from 'axios';

// apply base url for axios
const REACT_APP_APP_URL = process.env.REACT_APP_BASE_V3_API_URL;
const REACT_APP_URL = `${process.env.REACT_APP_REDIRECT_LINK_LIVE}/`;
const REACT_APP_GET_TEMP_API = process.env.REACT_APP_ADMIN_PANEL_API;

const axiosApi = axios.create({
  baseURL: REACT_APP_APP_URL,
});

const axiosgetStasheasyApi = axios.create({
  baseURL: REACT_APP_GET_TEMP_API,
});

const axiosStashfinApi = axios.create({
  baseURL: REACT_APP_URL,
});

axios.interceptors.request.use((config) => config);

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error),
);

export async function get(url, config) {
  return axiosApi
    .get(url, {
      ...config,
    })
    .then((response) => response.data);
}

export async function getStasheasy(url, config) {
  return axiosgetStasheasyApi.get(url, {
    ...config,
  });
}

export async function post(url, config) {
  return axiosApi
    .post(url, {
      ...config,
    })
    .then((response) => response.data)
    .catch((err) => err.response.data);
}

export async function postStasheasy(url, config) {
  return axiosgetStasheasyApi
    .post(url, {
      ...config,
    })
    .then((response) => response.data)
    .catch((err) => err.response.data);
}

export async function stashfinPost(url, config) {
  return axiosStashfinApi
    .post(url, {
      ...config,
    })
    .then((response) => response.data)
    .catch((err) => err.response.data);
}
export async function postUploadImg(url, formData) {
  return axiosgetStasheasyApi
    .post(url, formData)
    .then((response) => response.data)
    .catch((err) => err.response.data);
}
