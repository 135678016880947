import React, { memo } from 'react';
import { Typography } from '@mui/material';

const TypographyComponent = ({
  content,
  mt,
  my,
  mx,
  px,
  py,
  variant,
  color,
  fontWeight,
  textAlign,
  backgroundColor,
  borderRadius,
  fontSize,
}) => (
  <Typography
    mt={mt || 0}
    my={my || 0}
    mx={mx || 0}
    px={px || 0}
    py={py || 0}
    variant={variant || 'h6'}
    color={color || 'black'}
    fontWeight={fontWeight}
    textAlign={textAlign}
    backgroundColor={backgroundColor}
    borderRadius={borderRadius}
    whiteSpace="pre-wrap"
    fontSize={fontSize}
  >
    {content}
  </Typography>
);

export default memo(TypographyComponent);
