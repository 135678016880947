import React from 'react';
import { Box } from '@mui/material';
import CoverageTypo from './Components/coverageTypo';
import CoverageExplaination from './Components/coverageExplaination';
import CoverageAccordion from './Components/accordianCoverage';

const CoverageDetails = () => (
  <>
    <Box className="coverage-details-container">
      <CoverageTypo />
      <CoverageExplaination />
    </Box>
    <CoverageAccordion />
  </>
);

export default CoverageDetails;
