/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, createTheme, useMediaQuery } from '@mui/material';
import SimpleBackdrop from '../../components/sharedComponents/backdropLoader/loader';
import InsuranceDetails from './insuranceDetails';
import TransitionsModal from './Modals/desktopTransitionModal';
import BasicInfoPayment from './Components/basicInfoPayment';
import { createAppLandingPage, toggleLoader } from './Redux/actions';
import { logFirebaseEventFromWeb } from '../../utils/helper';
import InsuranceContextWrapper from './ContextWrapper/insuranceContextWrapper';
import Feedback from './Components/feedbackComponent';

const Paynow = () => {
  const { productId } = useParams();
  const theme = createTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const { loader, productDetailsInfo, basicInfoPaymentDetails, otpBypass, feedbackStatus } = useSelector(
    (state) => state.InsuranceReducer,
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const auth_token = urlParams.get('auth_token') || window.localStorage.getItem('appAuth');
  const device_id = urlParams.get('device_id');
  const utm_source = urlParams.get('utm_source');
  const utm_medium = urlParams.get('utm_medium');
  const utm_campaign = urlParams.get('utm_campaign');

  if (auth_token) {
    window.localStorage.setItem('authToken', auth_token);
  }
  if (device_id) {
    window.localStorage.setItem('deviceId', device_id);
  }
  if (utm_source) {
    window.localStorage.setItem('insuranceUtmSource', utm_source);
  }
  if (utm_medium) {
    window.localStorage.setItem('insuranceUtmMedium', utm_medium);
  }
  if (utm_campaign) {
    window.localStorage.setItem('insuranceUtmCampaign', utm_campaign);
  }
  if (productId) {
    window.localStorage.setItem('insuranceProductId', productId);
  }

  useEffect(() => {
    if (otpBypass) {
      logFirebaseEventFromWeb('insurance_otp_bypass');
    } else {
      logFirebaseEventFromWeb('insurance_action_authenticated');
    }
    const payload = {
      authToken:
        window.localStorage.getItem('authTokenFromOtp') || auth_token || window.localStorage.getItem('authToken'),
      productId: productId || window.localStorage.getItem('insuranceProductId'),
    };
    if (auth_token) {
      dispatch(createAppLandingPage(payload, navigate));
    } else if (Object.keys(basicInfoPaymentDetails.userDetails)?.length === 0) {
      dispatch(createAppLandingPage(payload, navigate));
    } else {
      dispatch(toggleLoader({ loader: false }));
    }
  }, []);

  if (loader && Object.keys(productDetailsInfo).length !== 0) {
    return (
      <>
        <InsuranceDetails />
        <Container>
          <SimpleBackdrop open={true} />
        </Container>
      </>
    );
  }

  if (loader && Object.keys(productDetailsInfo).length === 0) {
    return (
      <Container>
        <SimpleBackdrop open={true} />
      </Container>
    );
  }

  return (
    <InsuranceContextWrapper>
      {isLargeScreen && (
        <>
          <InsuranceDetails />
          {feedbackStatus ? (
            <Feedback />
          ) : (
            <TransitionsModal>
              <BasicInfoPayment />
            </TransitionsModal>
          )}
        </>
      )}
      {!isLargeScreen && (
        <>
          {' '}
          <InsuranceDetails payNow={true} />
          {feedbackStatus && <Feedback />}
        </>
      )}
      {/* {JSON.stringify(feedbackStatus)}
      {feedbackStatus && <Feedback />} */}
    </InsuranceContextWrapper>
  );
};

export default Paynow;
