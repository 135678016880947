import {
  POST_LOGINDETAIL_SUCCESS,
  POST_LOGINDETAIL_ERROR,
  POST_OTPDETAIL_SUCCESS,
  POST_OTPDETAIL_ERROR,
} from '../../../utils/constant';

const initialState = {
  loginSuccess: '',
  loginError: '',
  otpSuccess: '',
  otpError: '',
};

const loginReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case POST_LOGINDETAIL_SUCCESS:
      return {
        ...state,
        loginSuccess: action.payload,
        loginError: '',
        otpError: '',
        otpSuccess: '',
      };
    case POST_LOGINDETAIL_ERROR:
      return { ...state, loginError: action.payload };
    case POST_OTPDETAIL_SUCCESS:
      return { ...state, otpSuccess: action.payload };
    case POST_OTPDETAIL_ERROR:
      return { ...state, otpError: action.payload };
    case 'RESET_LOGIN':
      return {
        loginSuccess: '',
        loginError: '',
        otpSuccess: '',
        otpError: '',
      };
    default:
      return { ...state };
  }
};

export default loginReducer;
