import {
  CONTACT_DETAILS,
  CONTACT_SUCCESS,
  CONTACT_FAIL,
  MOBILE_NUMBER_SUCCESS,
  MOBILE_NUMBER_ERROR,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_ERROR,
  VERIFY_OTP_DATA,
  OTP_VALUE,
  SEND_OTP,
} from '../../../utils/constant';

const initialState = {
  message: [],
  signUpData: null,
  otp: new Array(4).fill(''),
  loading: false,
  errorMessage: '',
  phone: '',
};
const signUpReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case CONTACT_DETAILS:
      return {
        ...state,
        signUpData: action.payload,
        loading: true,
        errorMessage: '',
      };
    case CONTACT_SUCCESS:
      return {
        ...state,
        lists: action.payload,
        loading: false,
        errorMessage: '',
      };
    case CONTACT_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
        loading: false,
      };
    case MOBILE_NUMBER_SUCCESS:
      return {
        ...state,
        success: {
          message: action.payload,
        },
        loading: false,
        errorMessage: '',
      };
    case MOBILE_NUMBER_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        loading: false,
      };
    case VERIFY_OTP_DATA:
      return {
        ...state,
        VerifyOtpData: action.payload,
        loading: true,
        errorMessage: '',
      };
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        success: {
          message: action.payload,
        },
        loading: false,
        errorMessage: '',
      };
    case VERIFY_OTP_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        loading: false,
      };
    case OTP_VALUE:
      return { ...state, otp: action.payload, errorMessage: '' };
    case SEND_OTP:
      return { ...state, phone: action.payload, errorMessage: '' };
    default:
      return { ...state };
  }
};
export default signUpReducer;
