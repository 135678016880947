const initialState = {
  cityData: {},
};

const CityWiseIplReducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case 'CITY_DATA': {
      const { city, jsonData } = payload;
      const cityIndex = jsonData?.findIndex((data) => data.city_route === city);
      const cityData = cityIndex > -1 ? jsonData[cityIndex] : jsonData[0];
      return {
        ...state,
        cityData,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default CityWiseIplReducer;
