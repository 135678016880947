import React from 'react';
import { useMediaQuery, createTheme, Dialog, DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import TicketForm from './ticketForm';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(5),
  },
  '& .MuiPaper-root': {
    width: '90%',
    borderRadius: '12px',
  },
}));

export default function CreateTicketForm({ buttonStyle, setShowotpForm, setDetails, details }) {
  const theme = createTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      {isLargeScreen ? (
        <BootstrapDialog aria-labelledby="customized-dialog-title" open={isLargeScreen && true}>
          <DialogContent dividers>
            <TicketForm
              buttonStyle={buttonStyle}
              setShowotpForm={setShowotpForm}
              setDetails={setDetails}
              details={details}
            />
          </DialogContent>
        </BootstrapDialog>
      ) : (
        <TicketForm
          buttonStyle={buttonStyle}
          setShowotpForm={setShowotpForm}
          setDetails={setDetails}
          details={details}
        />
      )}
    </>
  );
}
