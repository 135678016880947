import {
  TICKET_SUMMARY_GET_COMMENTS_ERROR,
  TICKET_SUMMARY_GET_COMMENTS_SUCCESS,
  TICKET_SUMMARY_GET_TICKETS_ERROR,
  TICKET_SUMMARY_GET_TICKETS_SUCCESS,
  TICKET_SUMMARY_POST_COMMENT_ERROR,
  TICKET_SUMMARY_POST_COMMENT_SUCCESS,
  SAVE_CURRENT_TICKET_ID,
} from '../../../utils/constant';

const initialState = {
  ticketArray: [],
  ticketError: '',
  commentsArray: [],
  commentsError: '',
  postCommentSuccess: '',
  postCommentError: '',
  currentTicket: '',
};

const TicketSummaryReducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case TICKET_SUMMARY_GET_TICKETS_SUCCESS:
      return { ...state, ticketArray: payload?.data, ticketError: 'false' };
    case TICKET_SUMMARY_GET_TICKETS_ERROR:
      return { ...state, ticketError: payload };
    case TICKET_SUMMARY_GET_COMMENTS_SUCCESS:
      return { ...state, commentsArray: payload.data, postCommentSuccess: '', postCommentError: '' };
    case TICKET_SUMMARY_GET_COMMENTS_ERROR:
      return { ...state, commentsError: payload };
    case TICKET_SUMMARY_POST_COMMENT_SUCCESS:
      return { ...state, postCommentSuccess: payload.message, postCommentError: '' };
    case TICKET_SUMMARY_POST_COMMENT_ERROR:
      return { ...state, postCommentSuccess: '', postCommentError: 'Something Went Wrong' };
    case SAVE_CURRENT_TICKET_ID:
      return { ...state, currentTicket: payload.ticketId };
    default:
      return { ...state };
  }
};
export default TicketSummaryReducer;
