/* disable-eslint */
import { GET_POSTS, GET_POSTS_SUCCESS, GET_POSTS_FAIL } from '../utils/constant';

export const getPosts = () => ({
  type: GET_POSTS,
});

export const getPostsSuccess = (posts) => ({
  type: GET_POSTS_SUCCESS,
  payload: posts,
});

export const getPostsFail = (error) => ({
  type: GET_POSTS_FAIL,
  payload: error,
});
