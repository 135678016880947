import axios from 'axios';

const GAME_ENGINE_BASE_URL = process.env.REACT_APP_BASE_GAME_ENGINE_URL;

export const getStashCashApiCall = async () => {
  try {
    const res = await axios.get(`${GAME_ENGINE_BASE_URL}stashcash/sc/get-balance`, {
      headers: {
        auth_token: localStorage.getItem('auth_token'),
        // device_id: localStorage.getItem('device_id'),
      },
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const setShowGuideApiCall = async ({ payload }) => {
  try {
    const res = await axios.post(`${GAME_ENGINE_BASE_URL}stashcash/sc/user-guide`, payload, {
      headers: {
        auth_token: localStorage.getItem('auth_token'),
        // device_id: localStorage.getItem('device_id'),
      },
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const getTransactionDetailsApiCall = async ({ payload }) => {
  try {
    const { page = 1, limit = 20, filter_by = '' } = payload;
    const res = await axios.get(
      `${GAME_ENGINE_BASE_URL}stashcash/sc/getHistory?page=${page}&limit=${limit}&filter_by=${filter_by}`,
      {
        headers: {
          auth_token: localStorage.getItem('auth_token'),
          // device_id: localStorage.getItem('device_id'),
        },
      },
    );
    return res;
  } catch (err) {
    return err.response;
  }
};

export const getGamesApiCall = async () => {
  try {
    const res = await axios.get(`${GAME_ENGINE_BASE_URL}rewards/v1/getOffers`, {
      headers: {
        auth_token: localStorage.getItem('auth_token'),
        // device_id: localStorage.getItem('device_id'),
      },
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const transferStashcashApiCall = async ({ payload }) => {
  try {
    const res = await axios.post(
      `${GAME_ENGINE_BASE_URL}stashcash/sc/transfer-unlocked`,
      {
        sc_type: 'unlocked',
        amount: payload.amount,
        accountNo: payload?.bankDetails?.bankAccount,
        ifscCode: payload?.bankDetails?.ifscCode,
      },
      {
        headers: {
          auth_token: localStorage.getItem('auth_token'),
          // device_id: localStorage.getItem('device_id'),
        },
      },
    );
    return res;
  } catch (err) {
    return err.response;
  }
};

export const getBankDetailsApiCall = async () => {
  try {
    const res = await axios.get(`${GAME_ENGINE_BASE_URL}stashcash/sc/user/bank-details`, {
      headers: {
        auth_token: localStorage.getItem('auth_token'),
        // device_id: localStorage.getItem('device_id'),
      },
    });
    return res;
  } catch (err) {
    return err.response;
  }
};
