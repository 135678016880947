import { Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UnCoverageTypo from './uncoverageTypo';
import UnCoverageExplaination from './uncoverageExplaination';

const accordianStyling = {
  accordianContainer: {
    backgroundColor: 'white',
    padding: '0px 8px',
    boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.08), -2px -2px 4px rgba(0, 0, 0, 0.08)',
  },
  accordianDetails: {
    marginTop: '0px',
  },
};

const UnCoverageAccordion = () => (
  <Box className="uncoverage-accordian">
    <Accordion sx={accordianStyling.accordianContainer}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <UnCoverageTypo />
      </AccordionSummary>
      <AccordionDetails sx={accordianStyling.accordianDetails}>
        <UnCoverageExplaination />
      </AccordionDetails>
    </Accordion>
  </Box>
);

export default UnCoverageAccordion;
