/* eslint-disable max-statements */

import { useLocation } from 'react-router-dom';

const MetaAndTitle = () => {
  const location = useLocation();
  const PAGE_PATH_NAME = [
    '/',
    '/about-us',
    '/why-us',
    '/founders',
    '/partners',
    '/careers',
    '/loan-calculator',
    '/signup',
    '/sentinel',
    '/privacy-policy',
    '/terms-and-conditions',
    '/grievance-redressal-mechanism',
    '/customer-services',
    '/instant-personal-loans',
    '/newsroom',
    '/refer-and-earn',
    '/instant-personal-loans-in/delhi',
    '/instant-personal-loans-in/chennai',
    '/instant-personal-loans-in/hyderabad',
    '/instant-personal-loans-in/mumbai',
    '/instant-personal-loans-in/pune',
    '/instant-personal-loans-in/kolkata',
    '/website-disclosure',
  ];

  const addCanonicalLinks = (route) => {
    const newLink = document.createElement('link');
    newLink.rel = 'canonical';
    newLink.href = `https://www.stashfin.com${route}`;
    document.head.appendChild(newLink);
  };

  const path = PAGE_PATH_NAME.find((element) => element === location?.pathname);
  if (path === '/') {
    document.title = 'Apply for Instant Personal Loan Online in India | Stashfin';
    addCanonicalLinks(path);
    document.getElementsByTagName('META')[4].content =
      'Apply for instant personal loans online from Stashfin and get easy loan approval at low-interest rates. Register online and get the flexibility to repay in easy installments of 3 - 24 months.';
  }
  if (path === '/about-us') {
    document.title = 'About Us | Stashfin - Personal Loan App';
    addCanonicalLinks(path);
    document.getElementsByTagName('META')[4].content =
      'Leading fintech business Stashfin in India offers customers quick and simple personal loans at extremely affordable rates. Learn more';
  }
  if (path === '/customer-services') {
    document.title = 'Stashfin FAQs | Credit Line and Instant Personal Loans';
    addCanonicalLinks(path);
    document.getElementsByTagName('META')[4].content =
      'Do you require help regarding Stashfin services? Here are some of the important FAQs for availing credit line and instant personal loans from Stashfin App';
  }
  if (path === '/founders') {
    document.title = 'Meet Our Founders | Stashfin | Personal Loan';
    addCanonicalLinks(path);
    document.getElementsByTagName('META')[4].content = 'Meet our founders and cofounders - Stashfin';
  }
  if (path === '/careers') {
    document.title = 'Join the Fintech Revolution: StashFin Career Opportunities Await';
    addCanonicalLinks(path);
    document.getElementsByTagName('META')[4].content =
      'Discover exciting career opportunities with StashFin, a dynamic and innovative fintech company. Explore our open positions and join our team of passionate professionals.';
  }
  if (path === '/partners') {
    document.title = ' Apply for Quick and Easy Loans Online | Stashfin';
    addCanonicalLinks(path);
    document.getElementsByTagName('META')[4].content =
      'Personal loan: Apply for quick and easy loans online at low interest rates. Get easy approval with flexi EMI options';
  }
  if (path === '/loan-calculator') {
    document.title = 'Personal Loan EMI Calculator in India | Stashfin ';
    addCanonicalLinks(path);
    document.getElementsByTagName('META')[4].content =
      'Calculate your monthly Personal Loan payments with our EMI Calculator. Determine the loan amount, interest rate, and repayment tenure that suits your budget';
  }
  if (path === '/signup') {
    document.title = 'Sign Up | Stashfin Loan | Become a Customer';
    addCanonicalLinks(path);
    document.getElementsByTagName('META')[4].content =
      'Sign up and become our customer- We’re so excited to power your dreams';
  }
  if (path === '/sentinel') {
    document.title = 'Stashfin Sentinel Credit Line - Personal Loan for Defence Personnel';
    addCanonicalLinks(path);
    document.getElementsByTagName('META')[4].content =
      'Stashfin Sentinel Credit Line provides personal loan exclusively for our defence personnel. Know the exclusive benefits of sentinel customers.';
  }
  if (path === '/privacy-policy') {
    document.title = 'Privacy Policy | Stashfin Official Website ';
    addCanonicalLinks(path);
    document.getElementsByTagName('META')[4].content =
      'Privacy ploicy of EQX Analytics Private Limited (hereinafter referred to as “StashFin”, “we”, “our” or “us”)';
  }
  if (path === '/terms-and-conditions') {
    document.title = 'Terms and Conditions | Stashfin Official Website ';
    addCanonicalLinks(path);
    document.getElementsByTagName('META')[4].content =
      "Stashfin, is based out of Delhi and our website www.stashfin.com (“Website”) and hereinafter shall be referred to as “Stash Easy” or “Stashfin” or ' ‘us’ or ‘we’.";
  }
  if (path === '/grievance-redressal-mechanism') {
    document.title = 'Grievance Redressal mechanism | Customer Care | StashFin';
    addCanonicalLinks(path);
    document.getElementsByTagName('META')[4].content =
      'Customers are requested to address all their grievances at the first instance to the Grievance Redressal Officer. Get in touch with us now.';
  }
  if (path === '/instant-personal-loans') {
    document.title = 'Get Instant Personal Loans Online in India | StashFin';
    addCanonicalLinks(path);
    document.getElementsByTagName('META')[4].content =
      "Get quick access to funds with StashFin's instant personal loans in India. Apply online today and enjoy hassle-free approval and flexible repayment options.";
  }
  if (path === '/why-us') {
    document.title = 'Stashfin | Why choose us';
    document.getElementsByTagName('META')[4].content =
      'Know why to choose us and our power to access instant funds anytime you need';
  }
  if (path === '/newsroom') {
    document.title = 'Blogs | Stashfin';
    addCanonicalLinks(path);
    document.getElementsByTagName('META')[4].content =
      'Check out our blogs to know more on different topics on quick loan app, How to get the best instant loan in India, and so on.';
  }
  if (path === '/refer-and-earn') {
    document.title = 'Refer and Earn | Stashfin | Personal Loan App';
    addCanonicalLinks(path);
    document.getElementsByTagName('META')[4].content =
      'Earn extra income and amazing rewards with Stashfin. Any day, any time. Make more money by earning upto Rs.300/referral.';
  }
  if (path === '/instant-personal-loans-in/delhi') {
    document.title = 'Instant Personal Loan Online In New Delhi | Stashfin';
    addCanonicalLinks(path);
    document.getElementsByTagName('META')[4].content =
      'Apply for Instant Personal Loans Online in Delhi and get finance up to Rs. 5,00,000 quick disbursal within 24 hours* at lowest interest rates.';
  }
  if (path === '/instant-personal-loans-in/chennai') {
    document.title = 'Apply For Personal Loan Online In Chennai | Stashfin';
    document.getElementsByTagName('META')[4].content =
      'Apply for Personal Loan in Chennai. Get personal loan at lowest interest rates. Check your eligibility and apply online at Stashfin';
  }
  if (path === '/instant-personal-loans-in/hyderabad') {
    document.title = ' Online Small Personal Loan In Hyderabad | Stashfin';
    document.getElementsByTagName('META')[4].content =
      'Apply for online small personal loan in Hyderabad.Take advantage of flexible repayment options and quick loans with low interest rates.';
  }
  if (path === '/instant-personal-loans-in/mumbai') {
    document.title = 'Personal Loan In Mumbai | StashFin';
    document.getElementsByTagName('META')[4].content =
      'Get a personal loan in Mumbai up to Rs. 5 Lakhs at 0% interest for up to 30 days Check your eligibility and apply online for an instant personal loan in Mumbai.';
  }
  if (path === '/instant-personal-loans-in/pune') {
    document.title = 'Instant Online Loan In Pune | StashFin';
    document.getElementsByTagName('META')[4].content =
      'Apply for Personal Loan in Pune. Get personal loan at lowest interest rates. Check your eligibility and apply online at StashFin';
  }
  if (path === '/instant-personal-loans-in/kolkata') {
    document.title = 'Get Instant Loan In Kolkata | StashFin';
    document.getElementsByTagName('META')[4].content =
      'Get  instant loan in kolkata. Apply online and get a credit limit upto 5 lakh and get instant approval.';
  }
  if (path === '/website-disclosure') {
    document.title = 'Disclosure Under Default Loss Guarantee Guidelines';
  }
};

export default MetaAndTitle;
