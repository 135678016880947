/* eslint-disable complexity */
import {
  CLI_SEND_OTP,
  CLI_SEND_OTP_ERROR,
  CLI_SEND_OTP_SUCCESS,
  CLI_VERIFY_MOBILE,
  CLI_VERIFY_MOBILE_ERROR,
  CLI_VERIFY_MOBILE_SUCCESS,
  CLOSE_EVENT,
  LOAD_EVENT,
  VERIFY_CLI,
  VERIFY_CLI_ERROR,
  VERIFY_CLI_RESPONSE,
} from './constants';

const initialState = {
  flag: 1,
  loader: false,
  error: '',
  cliResponse: {},
  userDetails: {
    mobile: '',
  },
};

const CLIReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case CLI_VERIFY_MOBILE:
      return {
        ...state,
        loader: true,
        error: '',
        userDetails: {
          mobile: payload.mobile,
        },
      };
    case CLI_VERIFY_MOBILE_SUCCESS:
      return {
        ...state,
        flag: 2,
        loader: false,
        error: '',
      };
    case CLI_VERIFY_MOBILE_ERROR:
      return {
        ...state,
        loader: false,
        error: payload,
      };
    case CLI_SEND_OTP:
      return {
        ...state,
        loader: false,
        error: '',
      };
    case CLI_SEND_OTP_SUCCESS:
      return {
        ...state,
        loader: false,
        error: '',
      };
    case CLI_SEND_OTP_ERROR:
      return {
        ...state,
        loader: false,
        error: payload,
      };
    case VERIFY_CLI:
      return {
        ...state,
        loader: true,
        error: '',
      };
    case VERIFY_CLI_RESPONSE:
      return {
        ...state,
        flag: payload?.flag,
        loader: false,
        cliResponse: payload,
        error: '',
      };
    case VERIFY_CLI_ERROR:
      return {
        ...state,
        loader: false,
        error: payload,
      };
    case CLOSE_EVENT:
      return {
        ...state,
        flag: payload,
        loader: false,
        error: '',
      };
    case LOAD_EVENT:
      return {
        ...state,
        loader: true,
        error: '',
      };
    case 'SUCCESS_EVENT':
      return {
        ...state,
        flag: 'success',
        loader: false,
        cliResponse: { message: 'Your credit limit will be increased to ', amount: 1000000 },
        error: '',
      };
    default:
      return state;
  }
};

export default CLIReducer;
