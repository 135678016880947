import React from 'react';

import './home.scss';
import './makingMoney.scss';
import blogImgOne from '../../assets/images/blog-img-01.webp';
import blogImgTwo from '../../assets/images/blog-img-02.webp';
import { Buttonred } from '../../components/GlobalComponents/Buttons';

export default function MakingMoney() {
  const blogOneClick = () => {
    window.open('https://blog.stashfin.com/debt-trap-explained-solutions/', '_self');
  };
  const blogTwoClick = () => {
    window.open('https://blog.stashfin.com/healthy-financial-habits/', '_self');
  };
  return (
    <section className="makingmoney">
      <div className="container">
        <div className="row">
          <div className="col-md-12 page-title">
            <h2>
              Making money matters <strong>simple</strong>
            </h2>
            <p>Learn from our experts about how to manage your money</p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-6 col-lg-5 col-xl-4" data-aos="zoom-in" data-aos-delay="500">
            <div className="making-box">
              <img onClick={blogOneClick} src={blogImgOne} alt="Pay Debt | Stashfin" />
              <h3>FINANCE</h3>
              <h2 onClick={blogOneClick}>What is Debt Trap and Six Ways to Avoid Getting into it</h2>
              <p>
                ‘Don’t bite off more than you can chew’ is something we’ve often heard. It is also something that few
                seem to follow. That people would like to access funds, and additional funds to meet their growing needs
                is understandable. However, that the same people should be wary of falling into…
              </p>
              <div className="date-and-name">
                <span className="date">JUNE 8, 2022</span>
                <span className="red-dot"></span>
                <span className="name">By Stashfin</span>
              </div>
              <Buttonred onClick={blogOneClick}>Read More</Buttonred>
            </div>
          </div>
          <div className="col-md-6 col-lg-5 col-xl-4" data-aos="zoom-in" data-aos-delay="1000">
            <div className="making-box">
              <img onClick={blogTwoClick} src={blogImgTwo} alt="Personal Financial Planning | Stashfin" />
              <h3>FINANCE</h3>
              <h2 onClick={blogTwoClick}>Personal Financial Planning: The Importance of Financial Habits</h2>
              <p>
                The Importance of Healthy Financial Habits Spend wisely, save smartly, and lead a long, wealthy life You
                earn enough to make ends meet. You pay your bills on time. You create a monthly budget and stick to it.
                You’ve saved up for a rainy day, and even have a retirement…
              </p>
              <div className="date-and-name">
                <span className="date">JUNE 6, 2022</span>
                <span className="red-dot"></span>
                <span className="name">By Stashfin</span>
              </div>
              <Buttonred onClick={blogTwoClick}>Read More</Buttonred>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
