import { call, put, takeLatest } from 'redux-saga/effects';

import { getBankListApi, postBankApi } from '../services';

function* onGetBankList(data) {
  try {
    const bankList = yield call(getBankListApi, data.payload);
    if (bankList?.status === 200) {
      yield put({ type: 'GET_BANKLIST_SUCCESS', payload: bankList?.data });
    } else if (bankList?.status !== 200) {
      yield put({ type: 'GET_BANKLIST_ERROR', payload: bankList?.data });
    }
  } catch (error) {
    yield put({ type: 'GET_BANKLIST_ERROR', payload: error });
  }
}

function* onPostOnlineBanking(data) {
  try {
    const onlineBankingResult = yield call(postBankApi, data.payload);
    if (onlineBankingResult?.status === 200) {
      if (onlineBankingResult?.data?.process_by_code === 'gny') {
        return window.location.assign(onlineBankingResult?.data?.url);
      }
      yield put({
        type: 'POST_ONLINEBANKING_SUCCESS',
        payload: onlineBankingResult?.data,
      });
    } else if (onlineBankingResult?.status !== 200) {
      yield put({
        type: 'POST_ONLINEBANKING_ERROR',
        payload: onlineBankingResult?.data,
      });
    }
  } catch (error) {
    console.log('error====>', error);
  }
}

function* onPostBankUploads(data) {
  try {
    const fileUploadsResult = yield call(postBankApi, data.payload);
    if (fileUploadsResult?.status === 200) {
      yield put({
        type: 'POST_FILEUPLOADS_SUCCESS',
        payload: fileUploadsResult?.data,
      });
    } else if (fileUploadsResult?.status !== 200) {
      yield put({
        type: 'POST_FILEUPLOADS_ERROR',
        payload: fileUploadsResult?.data,
      });
    }
  } catch (error) {
    console.log('error==>', error);
  }
}

export default function* onBankingSagas() {
  yield takeLatest('GET_BANK_LIST', onGetBankList);
  yield takeLatest('POST_BANK_UPLOADS', onPostBankUploads);
  yield takeLatest('POST_ONLINE_BANKING', onPostOnlineBanking);
}
