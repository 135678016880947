import {
  CONTACT_DETAILS,
  CONTACT_SUCCESS,
  CONTACT_FAIL,
  MOBILE_NUMBER_SUCCESS,
  MOBILE_NUMBER_ERROR,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_ERROR,
  VERIFY_OTP_DATA,
  OTP_VALUE,
  SEND_OTP,
} from '../../../utils/constant';

export const signupPosts = (posts) => ({
  type: CONTACT_DETAILS,
  payload: posts,
});
export const signupPostsSuccess = (posts) => ({
  type: CONTACT_SUCCESS,
  payload: posts,
});
export const signupPostsFail = (error) => ({
  type: CONTACT_FAIL,
  payload: error,
});
export const otpPostSuccess = (posts) => ({
  type: MOBILE_NUMBER_SUCCESS,
  payload: posts,
});
export const otpPostsFail = (error) => ({
  type: MOBILE_NUMBER_ERROR,
  payload: error,
});
export const veryfyOtp = (posts) => ({
  type: VERIFY_OTP_DATA,
  payload: posts,
});
export const verifyOtpSuccess = (posts) => ({
  type: VERIFY_OTP_SUCCESS,
  payload: posts,
});
export const verifyOtpError = (error) => ({
  type: VERIFY_OTP_ERROR,
  payload: error,
});
export const otpValuePost = (otp) => ({
  type: OTP_VALUE,
  payload: otp,
});

export const sendOTP = (phone) => ({
  type: SEND_OTP,
  payload: phone,
});
