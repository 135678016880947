import {
  POST_LOGINDETAIL,
  POST_LOGINDETAIL_SUCCESS,
  POST_LOGINDETAIL_ERROR,
  POST_OTPDETAIL,
  POST_OTPDETAIL_SUCCESS,
  POST_OTPDETAIL_ERROR,
} from '../../../utils/constant';

export const loginDetailsPost = (post) => ({
  type: POST_LOGINDETAIL,
  payload: post,
});

export const loginDetailsPostSuccess = (postSuccess) => ({
  type: POST_LOGINDETAIL_SUCCESS,
  payload: postSuccess,
});

export const loginDetailsPostError = (postError) => ({
  type: POST_LOGINDETAIL_ERROR,
  payload: postError,
});

export const otpDetailsPost = (post) => ({
  type: POST_OTPDETAIL,
  payload: post,
});

export const otpDetailsPostSuccess = (postSuccess) => ({
  type: POST_OTPDETAIL_SUCCESS,
  payload: postSuccess,
});

export const otpDetailsPostError = (postError) => ({
  type: POST_OTPDETAIL_ERROR,
  payload: postError,
});
