import {} from '../../../utils/constant';

const initialState = {
  bankList: [],
  bankListError: {},
  message: '',
  status: '',
  bankSelected: '',
  bankId: '',
  bankTenure: '',
  year_from: '',
  year_to: '',
  year_month_to: '',
  year_month_from: '',
  month_to_char: '',
  month_from_char: '',
  onlineBankingPayload: '',
  onlineBankingStatus: '',
  fileUploadsPayload: '',
  fileUploadsStatus: '',
};

const bankingReducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case 'GET_BANKLIST_SUCCESS':
      return {
        ...state,
        bankList: payload.banks,
        status: payload.statement_status,
        message: payload.message,
        year_month_from: payload.year_month_from,
        year_month_to: payload.year_month_to,
        onlineBankingStatus: '',
        fileUploadsStatus: '',
      };
    case 'GET_BANK_SELECTED':
      return { ...state, bankSelected: payload.name, bankId: payload.bankId };
    case 'GET_BANK_TENURE':
      return {
        ...state,
        bankTenure: payload.tenure,
        year_month_to: payload.year_month_to,
        year_month_from: payload.year_month_from,
        month_to_char: payload.month_to_char,
        month_from_char: payload.month_from_char,
        year_from: payload.year_from,
        year_to: payload.year_to,
      };
    case 'GET_BANKLIST_ERROR':
      return {
        ...state,
        bankListError: payload,
        onlineBankingStatus: '',
        fileUploadsStatus: '',
      };
    case 'POST_ONLINEBANKING_SUCCESS':
      return {
        ...state,
        onlineBankingPayload: payload,
        onlineBankingStatus: payload?.status,
        fileUploadsStatus: '',
      };
    case 'POST_ONLINEBANKING_ERROR':
      return {
        ...state,
        onlineBankingStatus: payload?.status,
        onlineBankingPayload: payload,
        fileUploadsStatus: '',
      };
    case 'POST_FILEUPLOADS_SUCCESS':
      return {
        ...state,
        fileUploadsStatus: payload?.status,
        fileUploadsPayload: payload,
      };
    case 'POST_FILEUPLOADS_ERROR':
      return {
        ...state,
        fileUploadsStatus: payload?.status,
        fileUploadsPayload: payload,
      };
    default:
      return { ...state };
  }
};

export default bankingReducer;
