import { combineReducers } from 'redux';

import listReducer from './list.reducer';
import basicDetailsReducer from '../pages/basicDetails/reducer';
import commonDetailsReducer from '../pages/commonDetails/reducer';
import loginReducer from '../pages/login/reducers';
import signUpReducer from '../pages/signUp/reducer';
import dealaAndOfferReducer from '../pages/DealsAndOffers/reducer';
import feedbackReducer from '../pages/feedback/reducer';
import CustmServiceReducer from '../pages/CustomerServices/reducer';
import bankingReducer from '../pages/banking/reducer';
import CityWiseIplReducer from '../pages/CityWiseInstantPersonalLoans/reducers';
import TicketSummaryReducer from '../pages/TicketSummary/reducer';
import InsuranceReducer from '../pages/InsuranceJourney/Redux/reducer';
import CLIReducer from '../pages/CLIJourney/Redux/reducer';
import LoanRestructureReducer from '../pages/LoanRestructure/Redux/reducer';
import KYCReducer from '../pages/KYC/Redux/reducer';
import GoldWaitlistReducer from '../pages/GoldWaitlist/Redux/reducer';
import UpiCollectReducer from '../pages/UpiCollect/Redux/reducer';
import DealsAndOffersNewReducer from '../pages/DealsAndOffersNew/Redux/reducer';
import referAndEarnReducer from '../pages/ReferAndEarn/Redux/reducer';
import GameEngineReducer from '../pages/GameEngine/Redux/reducer';
import WealthReducer from '../pages/Wealth/Redux/reducer';
import IvrReducer from '../pages/Ivr/Redux/reducer';

const createRootReducer = combineReducers({
  listReducer,
  loginReducer,
  signUpReducer,
  basicDetailsReducer,
  commonDetailsReducer,
  dealaAndOfferReducer,
  feedbackReducer,
  CustmServiceReducer,
  bankingReducer,
  CityWiseIplReducer,
  TicketSummaryReducer,
  InsuranceReducer,
  CLIReducer,
  LoanRestructureReducer,
  KYCReducer,
  GoldWaitlistReducer,
  UpiCollectReducer,
  DealsAndOffersNewReducer,
  referAndEarnReducer,
  GameEngineReducer,
  WealthReducer,
  IvrReducer,
});

export default createRootReducer;
