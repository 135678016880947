import React from 'react';
import { Box } from '@mui/material';
import StashfinLogo from '../../../assets/images/logo-stashfin-new.webp';

const TicketHedaers = () => (
  <Box my={3}>
    <img src={StashfinLogo} />
  </Box>
);

export default TicketHedaers;
