import React from 'react';
import { Box } from '@mui/material';
import UnCoverageTypo from './Components/uncoverageTypo';
import UnCoverageExplaination from './Components/uncoverageExplaination';
import UnCoverageAccordion from './Components/accordianUnCoverage';

const UnCoverageDetails = () => (
  <>
    <Box className="uncoverage-details-container">
      <UnCoverageTypo />
      <UnCoverageExplaination />
    </Box>
    <UnCoverageAccordion />
  </>
);

export default UnCoverageDetails;
