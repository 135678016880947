import React from 'react';

import EasyApplication from '../../assets/images/wh-icon-01.webp';
import QuickProcess from '../../assets/images/wh-icon-02.webp';
import Digital from '../../assets/images/wh-icon-03.webp';

export default function OurQuality() {
  return (
    <div className="OurQuality">
      <div className="container">
        <div className="row">
          <div className="col-4 align-items-center col-md-4 d-flex">
            <img src={EasyApplication} alt="Stashfin Document Icon" loading="lazy" />
            <h3>Easy Application</h3>
          </div>
          <div className="col-4 align-items-center col-md-4 d-flex">
            <img src={QuickProcess} alt="Stashfin Quick Process Icon" loading="lazy" />
            <h3>Quick Process</h3>
          </div>
          <div className="col-4 align-items-center col-md-4 d-flex">
            <img src={Digital} alt="Stashfin Digital Icon" loading="lazy" />
            <h3>100% Digital</h3>
          </div>
        </div>
      </div>
    </div>
  );
}
