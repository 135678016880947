/* eslint-disable no-case-declarations */
/* eslint-disable max-lines */
/* eslint-disable complexity */
import {
  GET_CREATE_APPLICATION_SUCCESS,
  GET_OTP,
  GET_OTP_ERROR,
  GET_OTP_SUCCESS,
  GET_PAYMENT_LINK,
  GET_PAYMENT_LINK_ERROR,
  GET_PAYMENT_LINK_SUCCESS,
  GET_PRODUCT_DETAILS_SUCCESS,
  RESET_LOADER,
  SET_AUTH,
  SET_BUY_NOW,
  SET_FLAG,
  SET_OTP_DRAWER,
  VERIFY_OTP,
  VERIFY_OTP_ERROR,
  VERIFY_OTP_SUCCESS,
  GET_PAYMENT_STATUS_FAILED,
  GET_PAYMENT_STATUS_ERROR,
  GET_PAYMENT_STATUS_LOADING,
  GET_PAYMENT_STATUS_PENDING,
  GET_PAYMENT_STATUS_SUCCESS,
  CUSTOMER_NOT_ELIGIBLE,
  CUSTOMER_NOT_REGISTERED,
  LOADER_TOGGLE,
  BASIC_INFO,
  VERIFY_OTP_TOGGLE,
  PAYNOW_TOGGLE,
  RETRY_PAYMENT,
  RETRY_PAYMENT_ERROR,
  RETRY_PAYMENT_SUCCESS,
  OTP_BYPASS,
  INSURANCE_FEEDBACK_SUCCESS,
  FEEDBACK_EVENT_TRIGGER,
  POST_FEEDBACK,
  POST_FEEDBACK_SUCCESS,
  POST_FEEDBACK_ERROR,
  USER_UPDATED_INFO,
  USER_UPDATED_INFO_SUCCESS,
  USER_UPDATED_INFO_ERROR,
  UPDATE_FIELD_INFO,
  UPDATE_FIELD_INFO_ERROR,
  UPDATE_FIELD_INFO_SUCCESS,
  GET_INSURANCE_PRODUCTS_SUCCESS,
  GET_INSURANCE_PRODUCTS_ERROR,
  GET_INSURANCE_PRODUCTS_CATEGORY_SUCCESS,
  GET_INSURANCE_PRODUCTS_CATEGORY_ERROR,
  GET_INSURANCE_LISTING,
  GET_INSURANCE_LISTING_SUCCESS,
  GET_INSURANCE_LISTING_ERROR,
  INSURANCE_STASHCASH_BANNER,
  INSURANCE_STASHCASH_BANNER_SUCCESS,
  INSURANCE_STASHCASH_BANNER_ERROR,
  INSURANCE_STASHCASH_LEAD,
  INSURANCE_STASHCASH_LEAD_ERROR,
  INSURANCE_STASHCASH_LEAD_SUCCESS,
  GET_INSURANCE_PRODUCTS_CATEGORY,
  GET_LEADERBOARD,
  GET_LEADERBOARD_SUCCESS,
  GET_LEADERBOARD_ERROR,
} from '../constants';

const initialState = {
  loader: true,
  auth: null,
  device: null,
  paymentStatus: null,
  paymentStatusDetails: null,
  flag: 0,
  otpBypass: true,
  buyNow: false,
  showOtpDrawer: false,
  productDetailsInfo: {},
  basicInfoOtpDetails: {
    userDetails: {},
    loader: false,
  },
  otpForm: {
    loader: false,
  },
  basicInfoPaymentDetails: {
    userDetails: {},
    userDefaultDetails: {},
    fieldsData: [],
    selectBestOfferData: [],
    loader: false,
  },
  retryPaymentLoader: false,
  otpError: '',
  otpFormError: '',
  paymentFormError: '',
  retryPaymentError: '',
  showBasicInfo: false,
  showVerifyOtp: false,
  showPaynow: false,
  feedbackStatus: false,
  feedbackData: {},
  feedbackLoader: false,
  insuranceProducts: {},
  insuranceProductCategory: {},
  insuranceListing: {},
  insuranceBanner: [],
  leaderboardData: {},
  error: null,
};

const insuranceReducer = (state = initialState, action = {}) => {
  const { payload, type } = action;
  switch (type) {
    case GET_CREATE_APPLICATION_SUCCESS:
      window.localStorage.setItem('appId', payload?.createApplicationDetails?.application_uid);
      return {
        ...state,
        productDetailsInfo: payload.productDetails,
        basicInfoPaymentDetails: {
          ...state.basicInfoPaymentDetails,
          userDefaultDetails: payload?.createApplicationDetails?.defaultValues,
          fieldsData: payload?.createApplicationDetails?.fields,
          selectBestOfferData: payload?.selectBestOfferDetails || [],
        },
        loader: false,
        flag: 3,
      };
    case GET_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        productDetailsInfo: payload,
        loader: false,
      };
    case OTP_BYPASS:
      return {
        ...state,
        otpBypass: false,
      };
    case GET_OTP:
      return {
        ...state,
        basicInfoOtpDetails: {
          userDetails: payload.userDetails,
          loader: payload.loader,
        },
        otpError: '',
      };
    case GET_OTP_SUCCESS:
      return {
        ...state,
        otpError: '',
        flag: 2,
        showOtpDrawer: true,
        showVerifyOtp: true,
        basicInfoOtpDetails: { ...state.basicInfoOtpDetails, loader: false },
      };
    case GET_OTP_ERROR:
      return {
        ...state,
        otpError: payload,
        basicInfoOtpDetails: { ...state.basicInfoOtpDetails, loader: false },
      };
    case VERIFY_OTP:
      return {
        ...state,
        otpFormError: '',
        otpForm: { ...state.otpForm, loader: true },
      };
    case LOADER_TOGGLE:
      return {
        ...state,
        loader: payload.loader,
        otpFormError: '',
      };
    case BASIC_INFO:
      return {
        ...state,
        showBasicInfo: payload.show,
        otpFormError: '',
      };
    case VERIFY_OTP_TOGGLE:
      return {
        ...state,
        showVerifyOtp: payload.show,
      };
    case PAYNOW_TOGGLE:
      return {
        ...state,
        showPaynow: payload.show,
        showBasicInfo: false,
      };

    case CUSTOMER_NOT_ELIGIBLE:
      return {
        ...state,
        flag: action.payload,
        otpForm: { ...state.otpForm, loader: false },
      };

    case CUSTOMER_NOT_REGISTERED:
      return {
        ...state,
        flag: action.payload,
        otpForm: { ...state.otpForm, loader: false },
      };

    case VERIFY_OTP_SUCCESS:
      window.localStorage.setItem('appId', payload?.createApplicationDetails?.application_uid);
      return {
        ...state,
        otpFormError: '',
        otpForm: { ...state.otpForm, loader: false },
        showPaynow: true,
        basicInfoPaymentDetails: {
          ...state.basicInfoPaymentDetails,
          userDefaultDetails: payload?.createApplicationDetails?.defaultValues,
          fieldsData: payload?.createApplicationDetails?.fields,
        },
      };
    case VERIFY_OTP_ERROR:
      return {
        ...state,
        otpFormError: payload,
        otpForm: { ...state.otpForm, loader: false },
      };
    case GET_PAYMENT_LINK:
      return {
        ...state,
        paymentFormError: '',
        basicInfoPaymentDetails: {
          ...state.basicInfoPaymentDetails,
          loader: true,
        },
      };
    case GET_PAYMENT_LINK_SUCCESS:
      return {
        ...state,
        paymentFormError: '',
        basicInfoPaymentDetails: {
          ...state.basicInfoPaymentDetails,
          loader: false,
        },
      };
    case GET_PAYMENT_LINK_ERROR:
      return {
        ...state,
        paymentFormError: payload,
        basicInfoPaymentDetails: {
          ...state.basicInfoPaymentDetails,
          loader: false,
        },
      };

    case RETRY_PAYMENT:
      return {
        ...state,
        retryPaymentError: '',
        retryPaymentLoader: true,
      };
    case RETRY_PAYMENT_ERROR:
      return {
        ...state,
        retryPaymentError: payload,
        retryPaymentLoader: false,
      };
    case RETRY_PAYMENT_SUCCESS:
      return {
        ...state,
        retryPaymentError: '',
        retryPaymentLoader: false,
      };
    case SET_AUTH:
      return {
        ...state,
        auth: payload.authToken,
        device: payload.deviceId,
        flag: 3,
        buyNow: true,
        loader: false,
      };

    case RESET_LOADER:
      return {
        ...state,
        loader: false,
      };

    case SET_FLAG:
      return {
        ...state,
        flag: payload,
      };

    case SET_BUY_NOW:
      return {
        ...state,
        buyNow: payload,
      };

    case SET_OTP_DRAWER:
      return {
        ...state,
        showOtpDrawer: payload,
      };

    case GET_PAYMENT_STATUS_ERROR: {
      return {
        ...state,
        paymentStatus: 'error',
      };
    }

    case GET_PAYMENT_STATUS_SUCCESS: {
      return {
        ...state,
        paymentStatus: 'success',
        paymentStatusDetails: action.payload,
      };
    }

    case GET_PAYMENT_STATUS_FAILED: {
      return {
        ...state,
        paymentStatus: 'failed',
        paymentStatusDetails: action.payload,
      };
    }

    case GET_PAYMENT_STATUS_PENDING: {
      return {
        ...state,
        paymentStatus: 'pending',
      };
    }

    case GET_PAYMENT_STATUS_LOADING: {
      return {
        ...state,
        paymentStatus: 'loading',
        paymentStatusDetails: action.payload,
      };
    }
    case INSURANCE_FEEDBACK_SUCCESS: {
      return {
        ...state,
        feedbackStatus: payload?.feedbackStatus,
        feedbackData: payload?.feedbackData,
      };
    }
    case FEEDBACK_EVENT_TRIGGER: {
      return {
        ...state,
        feedbackStatus: payload,
      };
    }

    case POST_FEEDBACK:
      return {
        ...state,
        feedbackLoader: true,
      };
    case POST_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedbackLoader: false,
        feedbackStatus: false,
      };
    case POST_FEEDBACK_ERROR:
      return {
        ...state,
        feedbackLoader: false,
        feedbackStatus: false,
      };
    case USER_UPDATED_INFO:
      return {
        ...state,
        paymentFormError: '',
        basicInfoPaymentDetails: {
          ...state.basicInfoPaymentDetails,
          loader: true,
        },
      };
    case USER_UPDATED_INFO_SUCCESS:
      const updatedProductDetails = {
        ...state?.productDetailsInfo,
        product_details: {
          ...state?.productDetailsInfo?.product_details,
          data: {
            ...state?.productDetailsInfo?.product_details?.data,
            ...payload?.currentValue,
            premium: payload?.premiumAmount,
          },
        },
      };
      return {
        ...state,
        paymentFormError: '',
        basicInfoPaymentDetails: {
          ...state.basicInfoPaymentDetails,
          loader: false,
        },
        productDetailsInfo: updatedProductDetails,
      };
    case USER_UPDATED_INFO_ERROR:
      return {
        ...state,
        paymentFormError: payload,
        basicInfoPaymentDetails: {
          ...state.basicInfoPaymentDetails,
          loader: false,
        },
      };
    case UPDATE_FIELD_INFO:
      return {
        ...state,
        paymentFormError: '',
        basicInfoPaymentDetails: {
          ...state.basicInfoPaymentDetails,
          loader: true,
        },
      };
    case UPDATE_FIELD_INFO_SUCCESS:
      const { premiumAmount, updatedOptions, updatedKey, currentValue } = payload;
      const updatedDetails = [...state.basicInfoPaymentDetails.fieldsData].map((el) => {
        if (el?.key === updatedKey) {
          return { ...el, options: updatedOptions };
        }
        return el;
      });

      const updatedProductDetailsInfo = {
        ...state?.productDetailsInfo,
        product_details: {
          ...state?.productDetailsInfo?.product_details,
          data: {
            ...state?.productDetailsInfo?.product_details?.data,
            ...currentValue,
            premium: premiumAmount,
          },
        },
      };
      return {
        ...state,
        paymentFormError: '',
        basicInfoPaymentDetails: {
          ...state.basicInfoPaymentDetails,
          fieldsData: updatedDetails,
          loader: false,
        },
        productDetailsInfo: updatedProductDetailsInfo,
      };
    case UPDATE_FIELD_INFO_ERROR:
      const updatedDetail = [...state.basicInfoPaymentDetails.fieldsData].map((el) => {
        if (el?.key === payload?.updatedKey) {
          return { ...el, options: payload?.updatedOptions };
        }
        return el;
      });

      const updatedProductDetailInfo = {
        ...state?.productDetailsInfo,
        product_details: {
          ...state?.productDetailsInfo?.product_details,
          data: {
            ...state?.productDetailsInfo?.product_details?.data,
            ...payload?.currentValue,
          },
        },
      };

      return {
        ...state,
        paymentFormError: '',
        basicInfoPaymentDetails: {
          ...state.basicInfoPaymentDetails,
          fieldsData: updatedDetail,
          loader: false,
        },
        productDetailsInfo: updatedProductDetailInfo,
      };
    case GET_INSURANCE_PRODUCTS_SUCCESS:
      return { ...state, loader: false, insuranceProducts: payload };
    case GET_INSURANCE_PRODUCTS_ERROR:
      return { ...state, error: payload };
    case GET_INSURANCE_PRODUCTS_CATEGORY:
      return { ...state, loader: true, error: null };
    case GET_INSURANCE_PRODUCTS_CATEGORY_SUCCESS:
      return { ...state, loader: false, insuranceProductCategory: payload };
    case GET_INSURANCE_PRODUCTS_CATEGORY_ERROR:
      return { ...state, loader: false, error: payload };
    case GET_INSURANCE_LISTING:
      return { ...state, loader: true };
    case GET_INSURANCE_LISTING_SUCCESS:
      return { ...state, loader: false, insuranceListing: payload };
    case GET_INSURANCE_LISTING_ERROR:
      return { ...state, error: payload };
    case INSURANCE_STASHCASH_LEAD:
      return { ...state, loader: true, error: null };
    case INSURANCE_STASHCASH_LEAD_ERROR:
      return { ...state, loader: false, error: payload };
    case INSURANCE_STASHCASH_LEAD_SUCCESS:
      return { ...state, loader: false, error: null };
    case INSURANCE_STASHCASH_BANNER:
      return { ...state, error: null, loader: true };
    case INSURANCE_STASHCASH_BANNER_SUCCESS:
      return { ...state, error: null, loader: false, insuranceBanner: payload?.banner };
    case INSURANCE_STASHCASH_BANNER_ERROR:
      return { ...state, error: payload };
    case GET_LEADERBOARD: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_LEADERBOARD_SUCCESS: {
      return {
        ...state,
        leaderboardData: payload,
        loader: false,
      };
    }
    case GET_LEADERBOARD_ERROR: {
      return {
        ...state,
        leaderboardData: payload,
        loader: false,
      };
    }
    default:
      return state;
  }
};

export default insuranceReducer;
