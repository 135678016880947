import {
  GET_BASICDETAIL,
  GET_BASICDETAIL_SUCCESS,
  GET_BASICDETAIL_ERROR,
  POST_BASICDETAIL,
  POST_BASICDETAIL_SUCCESS,
  POST_BASICDETAIL_ERROR,
} from '../../../utils/constant';

const initialState = {
  message: [],
  basicDetailsData: [],
  loading: false,
  loadingSucess: false,
  errorMessage: '',
  lists: [],
  postBasicDetailData: [],
  postSuccess: '',
  postErrorMessage: '',
};

const basicDetailsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_BASICDETAIL:
      return {
        ...state,
        basicDetailsData: action.payload,
        errorMessage: '',
        loading: true,
      };

    case GET_BASICDETAIL_SUCCESS:
      return {
        ...state,
        lists: action.payload,
        errorMessage: '',
        loadingSucess: false,
      };

    case GET_BASICDETAIL_ERROR:
      return { ...state, errorMessage: action.payload, loading: false };

    case POST_BASICDETAIL:
      return {
        ...state,
        postBasicDetailData: action.payload,
        postErrorMessage: '',
        loading: true,
      };

    case POST_BASICDETAIL_SUCCESS:
      return {
        ...state,
        postSuccess: action.payload,
        postErrorMessage: '',
        loading: false,
      };

    case POST_BASICDETAIL_ERROR: {
      const postError = action.payload;
      const postErrorMessage = {
        status: postError.status,
        message: postError.data.errors,
      };
      return { ...state, postErrorMessage, loading: false };
    }
    default:
      return { ...state };
  }
};

export default basicDetailsReducer;
