import { put, takeLatest } from 'redux-saga/effects';

import {
  GET_OTP_POSTS_URL_PARAM,
  POST_LOGINDETAIL,
  POST_LOGINDETAIL_SUCCESS,
  POST_LOGINDETAIL_URL_PARAMS,
  POST_OTPDETAIL,
  POST_OTPDETAIL_SUCCESS,
} from '../../../utils/constant';
import { loginDetailsPostError, otpDetailsPostError } from '../actions';

function* onLoginPostDetail(data) {
  try {
    let loginResult = yield fetch(`${process.env.REACT_APP_BASE_V3_API_URL}${GET_OTP_POSTS_URL_PARAM}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data.payload),
    });
    loginResult = yield loginResult.json();
    if (loginResult?.status === true) {
      yield put({ type: POST_LOGINDETAIL_SUCCESS, payload: loginResult });
    } else if (loginResult?.status === false) {
      yield put(loginDetailsPostError(loginResult));
    }
  } catch (error) {
    yield put(loginDetailsPostError(error));
  }
}
function* onOtpPostDetail(data) {
  try {
    let otpResult = yield fetch(`${process.env.REACT_APP_BASE_V3_API_URL}${POST_LOGINDETAIL_URL_PARAMS}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data.payload),
    });
    otpResult = yield otpResult.json();
    if (otpResult?.status === true) {
      if (otpResult.results?.redirect_url) {
        return window.location.assign(otpResult?.results?.redirect_url);
      }
      yield put({ type: POST_OTPDETAIL_SUCCESS, payload: otpResult });
    } else if (otpResult?.status === false) {
      yield put(otpDetailsPostError(otpResult));
    }
  } catch (error) {
    yield put(otpDetailsPostError(error));
  }
}

export default function* onLoginSagas() {
  yield takeLatest(POST_LOGINDETAIL, onLoginPostDetail);
  yield takeLatest(POST_OTPDETAIL, onOtpPostDetail);
}
