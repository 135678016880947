import {
  SAVE_COMMENT,
  SAVE_COMMENT_SUCCESS,
  SAVE_COMMENT_FAIL,
  SEND_OTP_SUCCESS,
  VARIFY_OTP_SUCCESS,
  VARIFY_OTP_ERROR,
  SEND_OTP_ERROR,
} from '../../../utils/constant';

const initialState = {
  message: '',
  error: '',
  otpStatus: false,
  results: null,
  varifyOtpStatus: false,
  otpError: null,
  otpLoading: true,
};

const feedbackReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SAVE_COMMENT:
      return {
        ...state,
      };
    case SAVE_COMMENT_SUCCESS:
      return {
        ...state,
        message: action.payload,
        error: '',
      };
    case SAVE_COMMENT_FAIL:
      return {
        ...state,
        error: 'error',
        message: '',
      };
    case SEND_OTP_SUCCESS:
      return {
        ...state,
        otpStatus: action.payload.status,
        results: action.payload.results,
        otpError: null,
      };
    case SEND_OTP_ERROR:
      return {
        ...state,
        otpStatus: action.payload.status,
        otpError: action.payload.errors,
      };
    case VARIFY_OTP_SUCCESS:
      return {
        ...state,
        varifyOtpStatus: action.payload.status,
        results: 'varified successfully',
        otpError: null,
      };
    case VARIFY_OTP_ERROR:
      return {
        ...state,
        varifyOtpStatus: action.payload.status,
        otpError: action.payload.errors,
      };
    case 'RESET_STATE':
      return {
        ...state,
        results: null,
        otpStatus: false,
      };
    default:
      return state;
  }
};

export default feedbackReducer;
