import { call, put, takeLatest, select } from 'redux-saga/effects';
import axios from 'axios';

import {
  GET_COMMONDETAIL,
  GET_COMMONDETAIL_CATCH_ERROR,
  GET_COMMONDETAIL_URL_PARAM,
  POST_COMMONDETAIL,
  POST_COMMONDETAIL_ERROR,
  POST_COMMONDETAIL_SUCCESS,
} from '../../../utils/constant';
import { commonDetailApiCall, postCommonDetailApiCall } from '../services';
import { commonDetailsgetSuccess, commonDetailsgetFail, commonDetailsPostFail } from '../actons';

function* onCommonDetailsget() {
  try {
    const commonDetails = yield call(commonDetailApiCall);
    if (commonDetails?.status === true) {
      yield put(commonDetailsgetSuccess(commonDetails?.results));
    } else {
      yield put(commonDetailsgetFail(commonDetails));
    }
  } catch (error) {
    yield put(commonDetailsgetFail(error));
  }
}

function* onCommonDetailspost(data) {
  try {
    const commonDetail = yield call(postCommonDetailApiCall, data.payload);
    if (commonDetail.data.status === true) {
      yield put({
        type: POST_COMMONDETAIL_SUCCESS,
        payload: commonDetail.data.results,
      });
    } else if (commonDetail.data.status === false) {
      yield put({
        type: POST_COMMONDETAIL_ERROR,
        payload: commonDetail,
      });
    }
  } catch (error) {
    yield put(commonDetailsPostFail(error));
  }
}

export default function* onCommonDetailsagas() {
  yield takeLatest(GET_COMMONDETAIL, onCommonDetailsget);
  yield takeLatest(POST_COMMONDETAIL, onCommonDetailspost);
}
