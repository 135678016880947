import {
  GET_CREATE_APPLICATION,
  GET_CREATE_APPLICATION_SUCCESS,
  GET_OTP,
  GET_PAYMENT_STATUS,
  GET_PAYMENT_LINK,
  GET_PRODUCT_DETAILS,
  VERIFY_OTP,
  LOADER_TOGGLE,
  BASIC_INFO,
  VERIFY_OTP_TOGGLE,
  PAYNOW_TOGGLE,
  RETRY_PAYMENT,
  OTP_BYPASS,
  INSURANCE_FEEDBACK,
  FEEDBACK_EVENT_TRIGGER,
  POST_FEEDBACK,
  USER_UPDATED_INFO,
  UPDATE_FIELD_INFO,
  GET_INSURANCE_PRODUCTS,
  GET_INSURANCE_PRODUCTS_CATEGORY,
  GET_INSURANCE_LISTING,
  INSURANCE_STASHCASH_BANNER,
  INSURANCE_STASHCASH_LEAD,
  GET_LEADERBOARD,
} from '../constants';

export const getUserDetails = (payload) => ({
  type: 'GET_USER_DETAILS',
  payload,
});

export const productDetails = (payload) => ({
  type: GET_PRODUCT_DETAILS,
  payload,
});

export const getOtp = (payload) => ({
  type: GET_OTP,
  payload,
});

export const verifyOtp = (payload) => ({
  type: VERIFY_OTP,
  payload,
});

export const getPaymentLink = (payload) => ({
  type: GET_PAYMENT_LINK,
  payload,
});

export const getPaymentStatus = () => ({
  type: GET_PAYMENT_STATUS,
});

export const createAppLandingPage = (payload, navigate) => ({
  type: GET_CREATE_APPLICATION,
  payload,
  navigate,
});

export const createAppLandingPageSuccess = (payload) => ({
  type: GET_CREATE_APPLICATION_SUCCESS,
  payload,
});

export const toggleLoader = (payload) => ({
  type: LOADER_TOGGLE,
  payload,
});

export const toggleBasicInfo = (payload) => ({
  type: BASIC_INFO,
  payload,
});

export const toggleVeriftOtp = (payload) => ({
  type: VERIFY_OTP_TOGGLE,
  payload,
});

export const togglePaynow = (payload) => ({
  type: PAYNOW_TOGGLE,
  payload,
});

export const retryPayment = (payload) => ({
  type: RETRY_PAYMENT,
  payload,
});

export const getFeedbackDetails = (navigate) => ({
  type: INSURANCE_FEEDBACK,
  navigate,
});

export const feedbackEventTrigger = (payload) => ({
  type: FEEDBACK_EVENT_TRIGGER,
  payload,
});

export const postFeedbackAction = (payload, historyControl) => ({
  type: POST_FEEDBACK,
  payload,
  historyControl,
});

export const otpBypassToggle = (payload) => ({
  type: OTP_BYPASS,
  payload,
});

export const updateUserInfo = (payload) => ({
  type: USER_UPDATED_INFO,
  payload,
});

export const updateFieldsInfo = (payload) => ({
  type: UPDATE_FIELD_INFO,
  payload,
});

export const insuranceProducts = (payload) => ({
  type: GET_INSURANCE_PRODUCTS,
  payload,
});

export const insuranceProductsCategory = (payload) => ({
  type: GET_INSURANCE_PRODUCTS_CATEGORY,
  payload,
});

export const insuranceStashcashListing = (payload) => ({
  type: GET_INSURANCE_LISTING,
  payload,
});

export const insuranceStashcashLead = (payload, navigate) => ({
  type: INSURANCE_STASHCASH_LEAD,
  payload,
  navigate,
});

export const getInsuranceBanner = (payload, navigate) => ({
  type: INSURANCE_STASHCASH_BANNER,
  payload,
  navigate,
});

export const getLeaderboard = (payload) => ({
  type: GET_LEADERBOARD,
  payload,
});
