import { ARMY_LINK } from '../../../utils/constant';

const productId = window.localStorage.getItem('insuranceProductId');

export const PATH_WITH_OUT_HEADER_LINKS = [
  '/insurance/health',
  '/otppage',
  '/enach',
  '/upi-mandate',
  '/upi-mandate-status',
  '/e-sign',
  '/nach-options',
  '/customer',
  '/select-bank',
  '/consent-approval',
  '/initiate-error',
  '/ipl',
  '/stashcash',
  '/sentinel_offer',
  `/insurance/${productId}`,
  '/insurance/request-otp',
  '/insurance/verify-otp',
  `/insurance/paynow/${productId}`,
  '/insurance/payment-status',
  '/insurance-stashcash',
  '/insurance/pocket-insurance',
  '/insurance-stashcash-lead',
  '/insurance-stashcash-greet',
  '/cli',
  '/loan-restructure',
  '/document-upload',
  ARMY_LINK,
];

export const PATH_WITH_OUT_HEADER = [
  '/insurance/jobloss',
  '/cli',
  '/cli/nach',
  '/create-ticket',
  '/gold-waitlist',
  '/wealth/consent',
  '/wealth/bonds',
  '/wealth/details',
  '/wealth/faq',
  '/wealth/orders',
  '/wealth/no-investments',
  '/wealth/profile',
  '/wealth/tnc',
  '/wealth/disclaimer',
  '/growth/wealth/callback',
  '/rekyc-details-verification',
  '/rekyc-verification-result',
  '/upi-collect',
  '/okyc-v2-rekyc',
  '/website-disclosure',
  '/deals-and-offers/home',
  '/deals-and-offers/voucher-detail',
  '/deals-and-offers/cart',
  '/deals-and-offers/order-detail',
  '/deals-and-offers/payment-status',
  '/deals-and-offers/terms-and-conditions',
  '/deals-and-offers/your-orders',
  '/deals-and-offers/tnc',
  '/deals-and-offers/okyc',
  '/insurance/stashplus',
  '/upi-intent',
  '/refer-and-earn/play-and-win',
  '/insurance/leaderboard',
  '/rewards/home',
  '/rewards/transaction-history',
  '/rewards/task-todo',
  '/rewards/game-detail',
  '/rewards/faq',
  '/rewards/all-games',
  '/rewards/cashback',
  '/ivr',
  '/elevate-disable',
  '/okyc-init',
];
