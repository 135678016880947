import { React, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const SimpleBackdrop = ({ open, noScroll = false }) => {
  useEffect(() => {
    const originalOverflow = document.body.style.overflow;
    if (noScroll) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = originalOverflow;
    return function cleanUp() {
      document.body.style.overflow = originalOverflow;
    };
  }, [noScroll]);

  return (
    <div>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default SimpleBackdrop;
