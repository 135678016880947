import { call, put, takeLatest, select } from 'redux-saga/effects';

import { CONTACT_DETAILS, VERIFY_OTP_DATA, SEND_OTP } from '../../../utils/constant';
import { signUpApiCall, otpApiCall, verifyOtpApiCall, verifycaptchaApiCall } from '../services';
import { signupPostsFail, signupPostsSuccess, otpPostSuccess, verifyOtpSuccess, verifyOtpError } from '../actions';
import {
  setAuthToken,
  setPhone,
  setOTPVerified,
  deleteUTMSource,
  deleteReferBy,
  deleteUTMCampaign,
} from '../../../utils/localstorage';

const stateValue = (state) => state.signUpReducer;

function* onSignupPosts() {
  try {
    const stateName = yield select(stateValue);
    const verifycaptcharesponse = yield call(verifycaptchaApiCall, stateName.signUpData.recaptch_token);
    if (verifycaptcharesponse?.results?.success === true || stateName.signUpData.isRecaptchaRequired === false) {
      delete stateName.signUpData.isRecaptchaRequired;
      delete stateName.signUpData.recaptch_token;
      const response = yield call(signUpApiCall, stateName.signUpData);

      if (response && response?.status === true) {
        setPhone(stateName.signUpData.phone);
        yield put(signupPostsSuccess(response));
        setAuthToken(true);
        yield call(otpApiCall, stateName.signUpData.phone);
        yield put(otpPostSuccess(response));
        window.open('/otppage', '_self');
      } else if (response && response?.status === false) {
        if (typeof response?.errors === 'object') {
          let errorMessage = '';
          if (response?.errors?.pan_number) errorMessage = 'Invalid Pan Number';
          else if (response?.errors?.phone) errorMessage = 'Invalid phone number';
          else errorMessage = 'Something went wrong please try again';
          yield put(signupPostsFail(errorMessage));
        } else yield put(signupPostsFail(response?.errors));
      }
    } else yield put(signupPostsFail('Captcha Invalid'));
  } catch (error) {
    yield put(signupPostsFail(error.errors));
  }
}

function* onVerifyOtpPost() {
  try {
    const stateName = yield select(stateValue);
    const otpverify = yield call(verifyOtpApiCall, stateName.VerifyOtpData);
    if (otpverify?.status === true) {
      yield put(verifyOtpSuccess(otpverify));
      setOTPVerified(true);
      deleteUTMSource();
      deleteReferBy();
      deleteUTMCampaign();
      //  return window.location.assign(otpverify?.results?.redirect_url)
    } else if (otpverify?.status === false) {
      yield put(verifyOtpError('Enter valid otp'));
    }
  } catch (error) {
    yield put(verifyOtpError('Something went wrong'));
  }
}

function* onSendOTP() {
  try {
    const stateName = yield select(stateValue);
    yield call(otpApiCall, stateName.phone);
  } catch (error) {
    yield put(verifyOtpError(error));
  }
}

export default function* signUpSagas() {
  yield takeLatest(CONTACT_DETAILS, onSignupPosts);
  yield takeLatest(VERIFY_OTP_DATA, onVerifyOtpPost);
  yield takeLatest(SEND_OTP, onSendOTP);
}
