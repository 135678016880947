import React from 'react';
import { MenuItem, Typography } from '@mui/material';
import { InputFields } from '../inputFields';

const InsuranceSelectField = ({ register, errors, field, handleUserUpdate, handleFieldUpdate, setValue }) => (
  <>
    {' '}
    <InputFields
      select
      label={field?.label}
      {...register(field?.key, {
        required: `${field?.label} is required`,
        onChange: ({ target: { value } }) => {
          setValue(field?.key, value);
          if (field?.additional_info?.on_change && Object.keys(field?.additional_info?.on_change)?.length > 0) {
            const { on_change } = field?.additional_info || {};
            const updatedOptions = on_change?.conditional_option?.[`${value}`];
            const currentValue = { [field?.key]: value };
            const updatedKey = on_change?.key;
            setValue(updatedKey, null);
            return handleFieldUpdate({ updatedOptions, currentValue, updatedKey });
          }
          if (field?.additional_info?.fetch_premium) {
            return handleUserUpdate({ [field?.key]: value });
          }
        },
      })}
      error={!!errors?.[`${field?.key}`]}
      helperText={errors?.[`${field?.key}`]?.message}
      defaultValue={field?.initial_value}
      disabled={!field?.editable}
    >
      {field?.options?.map(({ text, value }) => (
        <MenuItem key={value} value={value}>
          {text}
        </MenuItem>
      ))}
    </InputFields>
    {field?.additional_info?.comment && (
      <Typography variant="caption" fontWeight={500} color="rgba(0, 0, 0, 0.60)">
        {field?.additional_info?.comment}
      </Typography>
    )}
  </>
);

export default InsuranceSelectField;
