import {
  TICKET_SUMMARY_GET_COMMENTS,
  TICKET_SUMMARY_GET_TICKETS,
  TICKET_SUMMARY_POST_COMMENTS,
  TICKET_SUMMARY_GET_TICKETS_ERROR,
  TICKET_SUMMARY_GET_TICKETS_SUCCESS,
  TICKET_SUMMARY_GET_COMMENTS_SUCCESS,
  TICKET_SUMMARY_GET_COMMENTS_ERROR,
  TICKET_SUMMARY_POST_COMMENT_SUCCESS,
  TICKET_SUMMARY_POST_COMMENT_ERROR,
  SAVE_CURRENT_TICKET_ID,
  CURRENT_TICKET,
} from '../../../utils/constant';

export const getTicket = (payload) => ({
  type: TICKET_SUMMARY_GET_TICKETS,
  payload,
});

export const getTicketSuccess = (payload) => ({
  type: TICKET_SUMMARY_GET_TICKETS_SUCCESS,
  payload,
});

export const getTicketFail = (payload) => ({
  type: TICKET_SUMMARY_GET_TICKETS_ERROR,
  payload,
});

export const getComments = (payload) => ({
  type: TICKET_SUMMARY_GET_COMMENTS,
  payload,
});

export const getCommentsSuccess = (payload) => ({
  type: TICKET_SUMMARY_GET_COMMENTS_SUCCESS,
  payload,
});

export const getCommentsFail = (payload) => ({
  type: TICKET_SUMMARY_GET_COMMENTS_ERROR,
  payload,
});

export const postComments = (payload) => ({
  type: TICKET_SUMMARY_POST_COMMENTS,
  payload,
});

export const postCommentSuccess = (payload) => ({
  type: TICKET_SUMMARY_POST_COMMENT_SUCCESS,
  payload,
});

export const postCommentFail = (payload) => ({
  type: TICKET_SUMMARY_POST_COMMENT_ERROR,
  payload,
});

export const setCurrentTicket = (payload) => ({
  type: CURRENT_TICKET,
  payload,
});

export const saveTicketSuccess = (payload) => ({
  type: SAVE_CURRENT_TICKET_ID,
  payload,
});
