import { GET_POSTS, GET_POSTS_SUCCESS, GET_POSTS_FAIL } from '../utils/constant';

const initialState = {
  lists: [],
  listItem: {},
  loadingList: false,
  loadinglistItem: false,
  error: {
    message: '',
  },
};

const listReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_POSTS:
      return { ...state, loadingList: true };
    case GET_POSTS_SUCCESS:
      return { ...state, lists: action.payload, loadingList: false };
    case GET_POSTS_FAIL:
      return {
        ...state,
        error: {
          message: 'Error',
        },
        loadingList: false,
      };
    default:
      return { ...state };
  }
};

export default listReducer;
