/* disable-eslint */
import axios from 'axios';

const INSURANCE_API = process.env.REACT_APP_MAGNETO_INSURANCE_API;
const V3_API = process.env.REACT_APP_BASE_V3_API_URL;
const REWARDS_BASE_URL = process.env.REACT_APP_BASE_GAME_ENGINE_URL;

export const getProductDetails = async (payload) => {
  try {
    const res = await axios.get(`${INSURANCE_API}product/${payload}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const requestOtp = async (payload) => {
  try {
    const res = await axios.post(`${INSURANCE_API}request-otp`, payload);
    return res;
  } catch (err) {
    return err.response.data;
  }
};

export const verifyOtpApi = async (payload) => {
  const utmSource = window.localStorage.getItem('insuranceUtmSource');
  const utmCampaign = window.localStorage.getItem('insuranceUtmCampaign');
  const utmMedium = window.localStorage.getItem('insuranceUtmMedium');

  try {
    const res = await axios.post(
      `${INSURANCE_API}verify-otp?utm_source=${utmSource}&utm_campaign=${utmCampaign}&utm_medium=${utmMedium}`,
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    return res;
  } catch (err) {
    return err.response.data;
  }
};

export const gettingPaymentLinkApi = async (payload) => {
  try {
    const res = await axios.put(
      `${INSURANCE_API}applications/${localStorage.getItem('appId')}`,
      { user_details: payload.data },
      {
        headers: {
          'Content-Type': 'application/json',
          auth_token: payload.authToken,
        },
      },
    );
    return res;
  } catch (err) {
    return err.response.data;
  }
};

export const gettingPaymentStatus = async () => {
  try {
    const res = await axios.get(`${INSURANCE_API}payment-status/${localStorage.getItem('appId')}`, {
      headers: {
        'Content-Type': 'application/json',
        auth_token: localStorage.getItem('authToken'),
      },
    });
    return res;
  } catch (err) {
    return err.response.data;
  }
};

export const createApplication = async (paylaod) => {
  const utmSource = window.localStorage.getItem('insuranceUtmSource');
  const utmCampaign = window.localStorage.getItem('insuranceUtmCampaign');
  const utmMedium = window.localStorage.getItem('insuranceUtmMedium');
  try {
    const res = await axios.post(
      `${INSURANCE_API}v2/application?utm_source=${utmSource}&utm_campaign=${utmCampaign}&utm_medium=${utmMedium}`,
      { product_uid: localStorage.getItem('insuranceProductId') },
      {
        headers: {
          'Content-Type': 'application/json',
          auth_token: paylaod,
        },
      },
    );
    return res;
  } catch (err) {
    return err.response;
  }
};

export const retryPaymentCall = async () => {
  try {
    const res = await axios.get(`${INSURANCE_API}applications/${localStorage.getItem('appId')}/retry-payment`, {
      headers: {
        auth_token: window.localStorage.getItem('authToken'),
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const insuranceFeedbackApi = async () => {
  try {
    const res = await axios.get(`${INSURANCE_API}feedback/${localStorage.getItem('appId')}`, {
      headers: {
        auth_token: window.localStorage.getItem('authToken'),
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const postFeedbackApi = async (paylaod) => {
  try {
    const res = await axios.post(`${INSURANCE_API}feedback/${localStorage.getItem('appId')}`, paylaod, {
      headers: {
        auth_token: window.localStorage.getItem('authToken'),
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const userUpdatedInfoApi = async (paylaod) => {
  try {
    const res = await axios.post(
      `${INSURANCE_API}product/updated-info/${localStorage.getItem('insuranceProductId')}`,
      paylaod,
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const getInsuranceProducts = async () => {
  try {
    const res = await axios.get(`${INSURANCE_API}home`);
    return res;
  } catch (err) {
    return err;
  }
};

export const getInsuranceProductsCategory = async (uid) => {
  try {
    const res = await axios.get(`${INSURANCE_API}sub-catagory/${uid}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const getInsuranceListing = async (uid) => {
  try {
    const res = await axios.get(`${INSURANCE_API}listing/${uid}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const postInsuranceStashcashLead = async (paylaod) => {
  try {
    const res = await axios.post(`${INSURANCE_API}stashcash/form/submit`, paylaod);
    return res;
  } catch (err) {
    return err;
  }
};

export const getInsuranceBanner = async () => {
  try {
    const res = await axios.get(`${INSURANCE_API}banner`);
    return res;
  } catch (err) {
    return err;
  }
};

export const getLeaderboardList = async () => {
  try {
    const res = await axios.get(`${V3_API}insurance/ranking`, {
      headers: {
        auth_token: window.localStorage.getItem('auth_token'),
        device_id: window.localStorage.getItem('device_id'),
      },
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const selectBestOfferApiCall = async (payload) => {
  try {
    const authTokenFromOtp = localStorage.getItem('authTokenFromOtp');
    const authToken = localStorage.getItem('authToken') || localStorage.getItem('auth_token');
    // const deviceId = localStorage.getItem('deviceId') || localStorage.getItem('device_id');

    let headers = {};
    if (authTokenFromOtp) {
      headers = { auth_token: authTokenFromOtp };
    } else if (authToken) {
      headers = { auth_token: authToken };
    }

    const res = await axios.post(`${REWARDS_BASE_URL}rewards/v1/selectBestOffers`, payload?.selectBestOfferPayload, {
      headers,
    });
    return res;
  } catch (err) {
    return err.response;
  }
};
